import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { use100vh } from "react-div-100vh";
import Modal from "react-modal";
import { RemoveScroll } from "react-remove-scroll";
import { useClickAway } from "react-use";

// Icons
import { Close } from "../icons/gallery-icons";

const PopUpContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: space-between;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  background-color: #e9cfc0;

  z-index: 100;
  width: 450px;
  height: 210px;

  padding: 13px 20px 20px 20px;

  & .top-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    width: 100%;

    & p {
      margin: 0;
    }

    & button {
      height: 19px;
    }
  }

  & .buttons-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-end;

    width: 100%;

    & a {
      line-height: 48px;

      border: 1px solid #000000;
    }

    & button {
      line-height: 43px;
      border: 1px solid #000000;
    }

    & a,
    & button {
      height: 50px;
      width: 130px;

      padding: 0;
      margin: 0 20px 0 0;

      text-align: center;

      transition: 250ms border-color ease, 250ms background-color ease;
      box-sizing: border-box;

      @media (hover: hover) and (pointer: fine) {
        &:hover {
          border-color: #87a3b4;
          background-color: #87a3b4;
        }
      }

      @media (max-width: 800px) {
        height: 30px;
        min-width: 70px;

        line-height: 27px;

        padding: 0 9px;
        margin: 0 15px 0 0;

        & svg {
          width: 7px;
          height: auto;
        }
      }
    }
  }
`;

Modal.setAppElement("#___gatsby");

export const InactivityPopUp = ({ isPopUpOpen, setIsPopUpOpen }) => {
  const height = use100vh();

  const ref = useRef(null);
  useClickAway(ref, () => {
    setIsPopUpOpen(false);
  });

  useEffect(() => {
    if (typeof window !== undefined) {
      document.addEventListener("keydown", (event) => onEscape(event));
    }
  }, [isPopUpOpen]);

  const onEscape = (event) => {
    if (event.keyCode === 27 && isPopUpOpen === true) {
      document.body.style.overflow = "";
      setIsPopUpOpen(false);
    }
  };

  return (
    <RemoveScroll>
      <Modal
        isOpen={isPopUpOpen}
        portalClassName={`ReactModalPortal-gallery`}
        className="artwork-lighbox-modal"
        contentLabel={`Artwork Gallery`}
        shouldFocusAfterRender={true}
      >
        <PopUpContainer height={height} ref={ref}>
          <div className="top-row">
            <p>Need some help? We are here for you!</p>

            <button onClick={() => setIsPopUpOpen(false)}>
              <Close />
            </button>
          </div>

          <div className="buttons-container">
            <a href="mailto:info@raweditions.com">Enquire</a>
            <button onClick={() => setIsPopUpOpen(false)}>No, thanks</button>
          </div>
        </PopUpContainer>
      </Modal>
    </RemoveScroll>
  );
};
