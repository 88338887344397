import React from "react";

export const SplashR = ({ fill }) => (
  <svg
    width="45"
    height="60"
    viewBox="0 0 45 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="icon-r"
  >
    <path
      d="M36.6048 46.4831V59.8375H42.8322L42.8775 46.6682C42.6875 41.702 41.1046 37.9052 38.1651 35.4176C35.732 33.3589 32.8964 32.9797 30.9291 32.7359H30.8568V30.9526H30.9336C32.9718 30.8296 34.9798 30.4004 36.8897 29.6795C41.5433 27.3273 44.2478 22.9075 44.2478 16.5011C44.2478 5.54402 38.667 0 28.3423 0H0V59.8375H6.16411V33.9187H24.1092C35.3656 33.9187 36.6003 42.7043 36.6048 46.4831ZM6.16411 5.39955H28.0845C34.4159 5.39955 38.0836 9.24154 38.0836 16.5056C38.0836 24.3928 33.8054 28.5598 26.3569 28.5598H6.16411V5.39955Z"
      fill={fill}
    />
  </svg>
);

export const SplashA = ({ fill }) => (
  <svg
    width="61"
    height="55"
    viewBox="0 0 61 55"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="icon-a"
  >
    <path
      d="M0 23.5441V31.1288L60.1758 54.1536V47.6931L44.3472 41.5893V13.1017L60.1758 7.00236V0.52832L0 23.5441ZM39.3725 39.7969L19.7812 32.6683L15.7427 31.2687C6.66158 28.438 3.36471 28.596 3.36471 28.596V26.0769C3.36471 26.0769 6.66158 26.2349 15.7427 23.4042L19.7812 22.0001L39.3725 14.9076V39.7969Z"
      fill={fill}
    />
  </svg>
);

export const SplashW = ({ fill }) => (
  <svg
    width="72"
    height="60"
    viewBox="0 0 72 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="icon-w"
  >
    <path
      d="M65.5214 0L56.3635 42.307L55.8388 44.6005C54.6133 51.1287 55.2148 55.4357 55.2148 55.4357H52.9535C52.9535 55.4357 53.1299 51.8239 51.6239 44.6005L51.1943 42.8668L40.2002 0H31.5216L20.5275 42.8713L20.0978 44.605C18.5918 51.8284 18.7682 55.4402 18.7682 55.4402H16.507C16.507 55.4402 17.1085 51.1106 15.8829 44.605L15.3583 42.3115L6.20031 0H0L13.8161 60H21.7576L33.2582 15.3544L33.3351 15.0474C35.0672 8.68623 34.7551 4.9842 34.7551 4.9842H36.9666C36.9666 4.9842 36.6546 8.68623 38.3867 15.0474L38.459 15.3544L49.9642 60H57.9056L71.7217 0H65.5214Z"
      fill={fill}
    />
  </svg>
);
