import React from "react";
import styled from "styled-components";
import { navigate } from "gatsby";

// Components
import { SplashR, SplashA, SplashW } from "../icons/splash-logo";

const LogoContainer = styled.div`
  height: 100px;
  position: relative;

  @media (max-width: 800px) {
    height: 58px;
  }

  & svg {
    position: absolute;
    transition: 500ms left ease-in-out, 500ms height ease-in-out;
    width: auto;

    @media (max-width: 800px) {
      display: none;
    }

    &.icon-r {
      left: 33.333%;
    }

    &.icon-a {
      left: 58.333%;
    }

    &.icon-w {
      left: calc(100% - 20px - 71px);
    }
  }

  &.active {
    height: 40px;

    // padding: 0;

    & svg {
      position: absolute;

      &.icon-r {
        left: 0;
        // left: 20px;
        height: 34px;

        @media (max-width: 910px) {
          height: 26px;
        }
      }

      &.icon-a {
        left: 118px;
        // top: 22px;
        top: 2px;
        height: 31px;

        @media (max-width: 910px) {
          left: 114px;
          height: 25px;
        }
      }

      &.icon-w {
        left: 294px;
        height: 34px;

        @media (max-width: 910px) {
          left: 255px;
          height: 27px;
        }
      }
    }
  }
`;

export const AnimatedLogo = ({
  animateLogo,
  setAnimateLogo,
  fillColor,
  showNav,
  setShowNav,
  logoSize,
  setLogoSize,
  location,
}) => {
  const triggerAnimation = () => {
    if (location !== `/`) {
      setAnimateLogo(!animateLogo);

      if (animateLogo) {
        setShowNav(!showNav);
        setLogoSize(`logo-large`);
      } else {
        const displayNav = setTimeout(() => {
          setShowNav(!showNav);
          setLogoSize(`logo-small`);
        }, 550);
        return () => clearTimeout(displayNav);
      }

      const transitionToHome = setTimeout(() => {
        navigate("/");
      }, 700);
      return () => clearTimeout(transitionToHome);
    }
  };

  return (
    <LogoContainer
      className={animateLogo ? `active logo` : `logo`}
      onClick={() => triggerAnimation()}
    >
      <SplashR fill={fillColor} />
      <SplashA fill={fillColor} />
      <SplashW fill={fillColor} />
    </LogoContainer>
  );
};
