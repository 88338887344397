import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";

export const CartIcon = ({ dot }) => (
  <Link to={`/cart/`} className="cart-icon">
    <svg
      width="18"
      height="23"
      viewBox="0 0 18 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 5.5V4.69231C5 2.6531 6.79086 1 9 1C11.2091 1 13 2.6531 13 4.69231V5.5"
        stroke="black"
        strokeWidth="1.2"
      />
      <rect
        x="0.5"
        y="5.5"
        width="17"
        height="17"
        stroke="black"
        strokeWidth="1.2"
      />

      {dot && <circle cx="9" cy="14" r="4" fill="#F1624E" />}
    </svg>
  </Link>
);

export const SearchIcon = ({ isSearchOpen, setIsSearchOpen }) => (
  <svg
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="search-icon"
  >
    <path
      d="M12.8463 12.833L18.5 18.5M14.9914 7.8773C14.9914 11.6755 11.8593 14.7546 7.99572 14.7546C4.13209 14.7546 1 11.6755 1 7.8773C1 4.07907 4.13209 1 7.99572 1C11.8593 1 14.9914 4.07907 14.9914 7.8773Z"
      stroke="black"
      strokeWidth="1.2"
    />
  </svg>
);

const OpenIconSvg = styled.svg`
  transform: rotate(${(props) => props.rotate}deg);
  transition: 100ms transform ease;
`;

export const OpenIcon = ({ rotate }) => (
  <OpenIconSvg
    rotate={rotate}
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 8L16 8" stroke="black" />
    <path d="M8 17L8 0" stroke="black" />
  </OpenIconSvg>
);
