import React from "react";

export const Arrow = () => (
  <svg
    width="101"
    height="19"
    viewBox="0 0 101 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="desktop-arrow"
  >
    <path d="M0 9L99 9.00001" stroke="black" strokeWidth="1" />
    <path d="M91 1L99.6735 9.32653L91.3469 18" stroke="black" strokeWidth="1" />
  </svg>
);

export const MobileArrow = () => (
  <svg
    width="37"
    height="9"
    viewBox="0 0 37 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="mobile-arrow"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M36.2597 4.48869L32.3439 0.72948L31.8245 1.27052L35.3187 4.625L0 4.625V5.375L34.889 5.375L31.9703 8.41536L32.5113 8.93476L35.9287 5.375H36V5.30071L36.2705 5.01891L36.5302 4.74839L36.2597 4.48869Z"
      fill="black"
    />
  </svg>
);

export const MailchimpArrow = () => (
  <svg
    width="101"
    height="19"
    viewBox="0 0 101 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="desktop-arrow"
  >
    <path d="M0 9L99 9.00001" stroke="#E9CFC0" strokeWidth="1.5" />
    <path
      d="M91 1L99.6735 9.32653L91.3469 18"
      stroke="#E9CFC0"
      strokeWidth="1.5"
    />
  </svg>
);
