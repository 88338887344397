import React from "react";

// Components
import { Search } from "./search";

// Hooks
import { UseSearchData } from "../hooks/useSearchData";

const SearchIndex = ({
  isSearchOpen,
  setIsSearchOpen,
  underlineColor,
  hoverColor,
  location,
}) => {
  const data = UseSearchData();

  if (data !== null) {
    return (
      <Search
        index={data.index}
        store={data.store}
        isSearchOpen={isSearchOpen}
        setIsSearchOpen={setIsSearchOpen}
        underlineColor={underlineColor}
        hoverColor={hoverColor}
        location={location}
      />
    );
  } else {
    return null;
  }
};

export default SearchIndex;
