import React from "react";

export const Facebook = () => (
  <svg
    width="12"
    height="22"
    viewBox="0 0 12 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.85024 11.9912H11.0696L11.6844 8.13001H7.84158V5.63064C7.84158 4.5775 8.38282 3.54522 10.0964 3.54522H11.8425V0.260678C11.8425 0.260678 10.2599 0 8.74329 0C5.57808 0 3.51377 1.85081 3.51377 5.1927V8.13262H0V11.9912H3.51485V21.3156H7.83508V11.9912H7.85024Z"
      fill="#E9CFC0"
    />
  </svg>
);

export const Instagram = () => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.8079 14.7271C14.1256 14.2945 15.6426 12.1228 15.1963 9.8764C14.75 7.63001 12.5093 6.15964 10.1916 6.59222C7.87396 7.02481 6.35693 9.19654 6.80324 11.4429C7.24955 13.6893 9.49021 15.1597 11.8079 14.7271Z"
      fill="#E9CFC0"
    />
    <path
      d="M16.6553 0H5.34418C2.40485 0 0 2.3309 0 5.17983V16.1436C0 18.9925 2.40485 21.3234 5.34418 21.3234H16.6553C19.5946 21.3234 21.9995 18.9925 21.9995 16.1436V5.1793C22 2.3309 19.5951 0 16.6553 0ZM10.9997 16.4024C9.82826 16.4024 8.6831 16.0657 7.70905 15.4348C6.73501 14.804 5.97584 13.9074 5.52754 12.8584C5.07924 11.8094 4.96194 10.6551 5.19048 9.54146C5.41902 8.42783 5.98314 7.4049 6.8115 6.60202C7.63985 5.79914 8.69524 5.25237 9.8442 5.03086C10.9932 4.80934 12.1841 4.92303 13.2664 5.35755C14.3487 5.79206 15.2737 6.52789 15.9246 7.47198C16.5754 8.41606 16.9228 9.52601 16.9228 10.6615C16.9211 12.1835 16.2965 13.6428 15.1861 14.719C14.0756 15.7953 12.5701 16.4007 10.9997 16.4024ZM17.5894 5.18298C17.3524 5.18298 17.1207 5.11486 16.9236 4.98723C16.7265 4.8596 16.5729 4.6782 16.4822 4.46596C16.3915 4.25372 16.3678 4.02018 16.414 3.79487C16.4603 3.56955 16.5744 3.36259 16.742 3.20015C16.9096 3.03771 17.1231 2.92709 17.3556 2.88227C17.5881 2.83745 17.829 2.86046 18.048 2.94837C18.267 3.03628 18.4541 3.18515 18.5858 3.37616C18.7175 3.56717 18.7877 3.79174 18.7877 4.02147C18.7877 4.32952 18.6615 4.62495 18.4368 4.84278C18.212 5.0606 17.9072 5.18298 17.5894 5.18298Z"
      fill="#E9CFC0"
    />
  </svg>
);

export const Pinterest = () => (
  <svg
    width="23"
    height="22"
    viewBox="0 0 23 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.0696 0C4.9535 0 0 4.77354 0 10.6607C0.000482592 12.7324 0.627617 14.7592 1.80474 16.4931C2.98186 18.227 4.65795 19.5931 6.62812 20.4241C6.5951 19.6791 6.62108 18.7865 6.81918 17.9769C7.03568 17.1104 8.24482 12.1658 8.24482 12.1658C8.24482 12.1658 7.89139 11.4855 7.89139 10.4798C7.89139 8.89955 8.84182 7.71972 10.025 7.71972C11.0328 7.71972 11.5183 8.44962 11.5183 9.32028C11.5183 10.2947 10.8737 11.7535 10.544 13.1038C10.2669 14.2351 11.1329 15.1579 12.2901 15.1579C14.3874 15.1579 15.8006 12.5621 15.8006 9.48868C15.8006 7.15248 14.1666 5.40334 11.1946 5.40334C7.83889 5.40334 5.74588 7.81513 5.74588 10.509C5.74588 11.438 6.02842 12.0929 6.47495 12.5996C6.679 12.8332 6.70768 12.9275 6.63299 13.1945C6.57886 13.3905 6.45925 13.8629 6.40783 14.0495C6.33422 14.3201 6.10743 14.4145 5.85359 14.3175C4.30724 13.708 3.58793 12.0783 3.58793 10.2441C3.58793 7.21505 6.24004 3.5838 11.4977 3.5838C15.7243 3.5838 18.5069 6.52842 18.5069 9.69097C18.5069 13.8733 16.0918 16.9988 12.5326 16.9988C11.3381 16.9988 10.2139 16.3732 9.82635 15.6688C9.82635 15.6688 9.18334 18.126 9.04803 18.5999C8.81259 19.421 8.35307 20.2437 7.93415 20.884C8.95332 21.1742 10.0106 21.3213 11.0734 21.3209C17.1895 21.3209 22.1414 16.5478 22.1414 10.6581C22.1414 4.76832 17.1825 0 11.0696 0Z"
      fill="#E9CFC0"
    />
  </svg>
);

export const EmailIcon = () => (
  <svg
    width="21"
    height="16"
    viewBox="0 0 21 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.26855 0H19.7314L10.4951 8.96094L1.26855 0ZM0 1.22876L10.4951 11.4272L21 1.22876V16H0V1.22876Z"
      fill="#E9CFC0"
    />
  </svg>
);
