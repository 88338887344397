exports.linkResolver = (doc) => {
  // URL for a category type
  if (doc.type === "artist") {
    return `/${doc.uid}/`;
  }

  if (doc.type === "fairs") {
    return `/fairs/`;
  }

  // URL for a product type
  if (doc.type === "product") {
    return `/${doc.uid}/`;
  }

  // URL for a page type
  if (doc.type === "artists") {
    return `/artists/`;
  }

  // URL for homepage
  if (doc.type === "splash") {
    return `/`;
  }

  // URL for a collection type
  if (doc.type === "collection") {
    return `/collection/${doc.uid}/`;
  }

  // Backup for all other types
  return `/${doc.uid}/`;
};
