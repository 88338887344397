import React, { useContext } from "react";

// Context
import { StoreContext } from "../context/store-context";

export const AddToCart = ({
  priceOnApplication,
  variantId,
  quantity,
  available,
  price,
  ...props
}) => {
  const { addVariantToCart, loading, checkout } = useContext(StoreContext);

  const isProductInCart =
    checkout.lineItems.length >= 1
      ? checkout.lineItems
          .filter((product) => product.variant !== null)
          .map((product) => product.variant.id)
          .includes(variantId)
      : false;

  const addToCart = (e) => {
    e.preventDefault();
    addVariantToCart(variantId, quantity);
  };

  if (priceOnApplication !== null) {
    if (priceOnApplication.length >= 1) {
      return null;
    } else {
      return (
        <>
          {available && price !== "£0" && (
            <button
              className="add-to-cart"
              type="submit"
              onClick={addToCart}
              disabled={!available || loading || isProductInCart}
              {...props}
            >
              {isProductInCart ? `Added!` : `Purchase`}
            </button>
          )}
        </>
      );
    }
  } else {
    return (
      <>
        {available && price !== "£0" && (
          <button
            className="add-to-cart"
            type="submit"
            onClick={addToCart}
            disabled={!available || loading || isProductInCart}
            {...props}
          >
            {isProductInCart ? `Added!` : `Purchase`}
          </button>
        )}
      </>
    );
  }
};
