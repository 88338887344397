import React from "react";
import { Link } from "gatsby";

export const Logo = ({ fillColor }) => (
  <div className="logo">
    <Link to={`/`}>
      <svg
        width="335"
        height="35"
        viewBox="0 0 335 35"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M118 15.0462V19.3455L152.11 32.3968V28.7348L143.138 25.2749V9.12705L152.11 5.66973V2L118 15.0462ZM140.318 24.2589L129.213 20.2181L126.924 19.4248C121.776 17.8203 119.907 17.9098 119.907 17.9098V16.4819C119.907 16.4819 121.776 16.5714 126.924 14.9669L129.213 14.171L140.318 10.1507V24.2589Z"
          fill={fillColor}
        />
        <path
          d="M20.749 26.3483V33.9181H24.2789L24.3045 26.4533C24.1969 23.6383 23.2996 21.4861 21.6334 20.076C20.2542 18.9091 18.6469 18.6941 17.5318 18.5559H17.4908V17.5451H17.5343C18.6897 17.4754 19.8278 17.2321 20.9105 16.8234C23.5483 15.4901 25.0813 12.9848 25.0813 9.35346C25.0813 3.14256 21.9179 0 16.0655 0H0V33.9181H3.49405V19.2264H13.666C20.0466 19.2264 20.7464 24.2064 20.749 26.3483ZM3.49405 3.06067H15.9193C19.5082 3.06067 21.5872 5.23845 21.5872 9.35601C21.5872 13.8267 19.1622 16.1888 14.9401 16.1888H3.49405V3.06067Z"
          fill={fillColor}
        />
        <path
          d="M331.14 0L325.949 23.9812L325.652 25.2812C324.957 28.9816 325.298 31.423 325.298 31.423H324.016C324.016 31.423 324.116 29.3757 323.262 25.2812L323.019 24.2985L316.787 0H311.868L305.636 24.3011L305.392 25.2838C304.539 29.3783 304.639 31.4256 304.639 31.4256H303.357C303.357 31.4256 303.698 28.9714 303.003 25.2838L302.706 23.9837L297.515 0H294L301.831 34.0102H306.333L312.852 8.70345L312.896 8.52943C313.877 4.92368 313.701 2.82523 313.701 2.82523H314.954C314.954 2.82523 314.777 4.92368 315.759 8.52943L315.8 8.70345L322.322 34.0102H326.823L334.655 0H331.14Z"
          fill={fillColor}
        />
      </svg>
    </Link>
  </div>
);

export const MobileLogo = ({ fillColor }) => (
  <div className="logo">
    <Link to={`/`}>
      <svg
        width="153"
        height="28"
        viewBox="0 0 153 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.544 21.7496V28H19.3661L19.3865 21.8341C19.3047 19.5113 18.589 17.7376 17.2597 16.5762C16.1554 15.6048 14.8875 15.4359 13.9877 15.3303H13.9468V14.5068H13.9877C14.3149 14.4857 15.501 14.3801 16.6667 13.9155C18.773 12.8175 20 10.7481 20 7.74962C20 2.59729 17.4847 0 12.8221 0H0V27.9789H2.78119V15.8582H10.8998C15.9918 15.8582 16.544 19.9759 16.544 21.7496ZM2.78119 2.51282H12.6994C15.5624 2.51282 17.2188 4.30769 17.2188 7.70739C17.2188 11.4027 15.2761 13.3454 11.9223 13.3454H2.78119V2.51282Z"
          fill={fillColor}
        />
        <path
          d="M51 11.7323L51 15.2677L79 26L79 22.9907L71.6371 20.1498L71.6371 6.85017L79 4.00926L79 1L51 11.7323ZM69.3231 19.3081L60.2141 16.0042L58.3418 15.3519C54.1134 14.0261 52.5778 14.1103 52.5778 14.1103L52.5778 12.9318C52.5778 12.9318 54.1134 12.995 58.3418 11.6902L60.2141 11.0379L69.3231 7.73401L69.3231 19.3081Z"
          fill={fillColor}
        />
        <path
          d="M150.149 0L145.946 19.7412L145.697 20.8157C145.135 23.8706 145.405 25.8721 145.405 25.8721H144.365C144.365 25.8721 144.448 24.1866 143.762 20.8157L143.554 20.015L138.497 0H134.503L129.446 20.015L129.238 20.8157C128.552 24.1866 128.635 25.8721 128.635 25.8721H127.595C127.595 25.8721 127.865 23.8495 127.303 20.8157L127.074 19.7412L122.851 0H120L126.346 28H130.008L135.293 7.16328L135.335 7.0158C136.125 4.04515 135.98 2.31753 135.98 2.31753H136.999C136.999 2.31753 136.854 4.04515 137.644 7.0158L137.686 7.16328L142.971 28H146.633L153 0H150.149Z"
          fill={fillColor}
        />
      </svg>
    </Link>
  </div>
);
