import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import styled from "styled-components";

// Components
import { Mailchimp } from "../forms/mailchimp";

const FooterWrapper = styled.footer`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;

  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 20px;
  align-items: center;

  padding: 0 20px;

  z-index: 1;

  background-color: ${(props) => props.bgColor};

  & .left-column {
    grid-column: 1 / 8;

    & span,
    & a {
      font-size: 12px;
      line-height: 48px;
      letter-spacing: 0.04em;

      margin: 0 15px 0 0;
    }

    & a {
      transition: 250ms color ease;

      &.current {
        color: ${(props) => props.hoverColor};
      }

      @media (hover: hover) and (pointer: fine) {
        &:hover {
          color: ${(props) => props.hoverColor};
        }
      }
    }
  }

  & .right-column {
    grid-column: 8 / 13;
    letter-spacing: 0.04em;
  }
`;

export const Footer = ({
  bgColor,
  hoverColor,
  underlineColor,
  showMailchimp,
}) => {
  const data = useStaticQuery(graphql`
    {
      prismicFairs {
        data {
          visible_on_website
        }
      }
    }
  `);

  return (
    <FooterWrapper bgColor={bgColor} hoverColor={hoverColor}>
      <div className="left-column">
        <span>©RAW Editions</span>
        <a href="mailto:info@raweditions.com">info@raweditions.com</a>
        <a href="tel:+447841116959">+44 (0)784 111 6959</a>
        <Link to={`/support/`} activeClassName="current">
          Support
        </Link>
        <Link to={`/our-alphabet/`} activeClassName="current">
          Our Alphabet
        </Link>

        {data.prismicFairs.data.visible_on_website === true && (
          <Link to={`/fairs/`} activeClassName="current">
            Fairs
          </Link>
        )}
      </div>

      {showMailchimp && (
        <div className="right-column">
          <Mailchimp hoverColor={hoverColor} underlineColor={underlineColor} />
        </div>
      )}
    </FooterWrapper>
  );
};
