import React, { useState, useEffect } from "react";
import { Location } from "@reach/router";
import styled from "styled-components";
import { Normalize } from "styled-normalize";

// Styles
import GlobalStyles from "../styles/globalStyles";

// Get Urls correct
import { Link } from "gatsby";
import { PrismicProvider } from "@prismicio/react";
import linkResolver from "../link-resolver/linkResolver";

// Context
import { PageType } from "../context/page-type";
import { FilterContext } from "../context/filter-context";

// Hooks
import { useMediaQuery } from "../hooks/useMediaQuery";

// Components
import { App } from "./app";
import { DefaultSEO } from "./default-seo";
import { Cookies } from "../cookies/cookies";

// Utils
import kebabcase from "lodash.kebabcase";

// Context

const Container = styled.div`
  position: relative;

  display: flex;
  min-height: 100vh;
  flex-direction: column;

  width: 100%;

  // transition: 250ms background-color ease;

  &.artwork-is-active {
    background-color: #e9cfc0;

    & header {
      & .logo {
        & svg {
          & path {
            fill: #91a8b7;
          }
        }
      }

      & .menu-links {
        & li {
          & a.current {
            color: #91a8b7;
          }
        }
      }
    }

    & footer {
      display: none;
    }
  }

  &.page-home,
  &.page-artist,
  &.page-shop,
  &.page-artwork,
  &.page-contact {
    cursor: url(/icons/cursor-circle-on-white@1x.png) 9 9, pointer;
    cursor: -webkit-image-set(
          url(/icons/cursor-circle-on-white@1x.png) 1x,
          url(/icons/cursor-circle-on-white@2x.png) 2x
        )
        9 9,
      pointer;

    & a,
    & button {
      cursor: url(/icons/cursor-circle-on-white@1x.png) 9 9, pointer;
      cursor: -webkit-image-set(
            url(/icons/cursor-circle-on-white@1x.png) 1x,
            url(/icons/cursor-circle-on-white@2x.png) 2x
          )
          9 9,
        pointer;
    }

    & .artwork-container {
      & a {
        cursor: url(/icons/cursor-plus-on-white@1x.png) 9 9, pointer;
        cursor: -webkit-image-set(
              url(/icons/cursor-plus-on-white@1x.png) 1x,
              url(/icons/cursor-plus-on-white@2x.png) 2x
            )
            9 9,
          pointer;
      }
    }
  }

  &.page-our-story {
    cursor: url(/icons/cursor-circle-on-green@1x.png) 9 9, pointer;
    cursor: -webkit-image-set(
          url(/icons/cursor-circle-on-green@1x.png) 1x,
          url(/icons/cursor-circle-on-green@2x.png) 2x
        )
        9 9,
      pointer;

    & a,
    & button {
      cursor: url(/icons/cursor-circle-on-green@1x.png) 9 9, pointer;
      cursor: -webkit-image-set(
            url(/icons/cursor-circle-on-green@1x.png) 1x,
            url(/icons/cursor-circle-on-green@2x.png) 2x
          )
          9 9,
        pointer;
    }
  }

  &.page-support,
  &.page-glossary,
  &.page-cart,
  &.page-fairs,
  &.page-search {
    cursor: url(/icons/cursor-circle-on-buff@1x.png) 9 9, pointer;
    cursor: -webkit-image-set(
          url(/icons/cursor-circle-on-buff@1x.png) 1x,
          url(/icons/cursor-circle-on-buff@2x.png) 2x
        )
        9 9,
      pointer;

    & a,
    & button {
      cursor: url(/icons/cursor-circle-on-buff@1x.png) 9 9, pointer;
      cursor: -webkit-image-set(
            url(/icons/cursor-circle-on-buff@1x.png) 1x,
            url(/icons/cursor-circle-on-buff@2x.png) 2x
          )
          9 9,
        pointer;
    }
  }
`;

const Layout = ({ children }) => {
  const [pageType, setPageType] = useState({
    page: ``,
    background: `#fff`,
    banner: true,
    bannerBackground: `#fff`,
    fillColor: `#DBAF96`,
  });

  const [filterSettings, setFilterSettings] = useState({
    activeArtistFilter: [],
    activePriceFilter: null,
    hideSoldItems: false,
  });

  useEffect(() => {
    document.body.style.backgroundColor = pageType.background;
  }, [pageType]);

  // Media Query
  let isDesktop = useMediaQuery("(min-width: 801px)");
  let showFooter = useMediaQuery("(min-width: 1110px)");

  useEffect(() => {
    console.log(
      "%cDevelopment: Kieran Startup: www.kieranstartup.co.uk",
      "color:red; font-family:Helvetica Neue;  font-size: 20px"
    );
  }, []);

  return (
    <PrismicProvider
      linkResolver={linkResolver.linkResolver}
      internalLinkComponent={({ href, ...props }) => (
        <Link to={href} {...props} />
      )}
    >
      <PageType.Provider value={[pageType, setPageType]}>
        <Location>
          {({ location }) => {
            return (
              <>
                <Container
                  location={location}
                  className={`page-${pageType.page} ${kebabcase(
                    location.pathname
                  )}`}
                  id="site-container"
                >
                  <Normalize />
                  <GlobalStyles />
                  <DefaultSEO location={location} />
                  <FilterContext.Provider
                    value={[filterSettings, setFilterSettings]}
                  >
                    <App
                      location={location}
                      children={children}
                      pageType={pageType}
                      isDesktop={isDesktop}
                      showFooter={showFooter}
                      setFilterSettings={setFilterSettings}
                    />

                    {pageType.page !== `artwork` && (
                      <Cookies location={location} />
                    )}
                  </FilterContext.Provider>
                </Container>
              </>
            );
          }}
        </Location>
      </PageType.Provider>{" "}
    </PrismicProvider>
  );
};

export default Layout;
