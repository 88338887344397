import React from "react";
import { useLocation } from "@reach/router"; // this helps tracking the location
import {
  FacebookShareButton,
  PinterestShareButton,
  EmailShareButton,
} from "react-share";

// Icons
import { Facebook, Pinterest, EmailIcon } from "../icons/social-icons";

export const ShareButtons = ({ title, text, image }) => {
  const location = useLocation();

  return (
    <>
      <FacebookShareButton
        resetButtonStyle={false}
        url={location.href}
        quote={text}
        className="facebook-icon"
      >
        <Facebook />
      </FacebookShareButton>

      <PinterestShareButton
        resetButtonStyle={false}
        url={location.href}
        media={image}
        description={title}
        className="pinterest-icon"
      >
        <Pinterest />
      </PinterestShareButton>

      <a
        href={`mailto:info@raweditions.com?subject=${title}`}
        className="email-icon"
      >
        <EmailIcon />
      </a>
    </>
  );
};
