import React, { useState } from "react";
import styled from "styled-components";
import { graphql, navigate } from "gatsby";
import { use100vh } from "react-div-100vh";

// Previews
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

// SEO
import { PageSeo } from "../components/seo/seo";

// Icons
// import { SplashR, SplashA, SplashW } from "../components/icons/splash-logo";
import { AnimatedLogo } from "../components/global/animated-logo";

const Page = styled.div`
  & .header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;

    height: 75px;

    transition: 249ms background-color ease;
    background-color: #fffcfa;

    z-index: 1;

    @media (max-width: 800px) {
      height: 56px;
    }
  }
`;

const SplashContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  z-index: 1000;

  @media (max-width: 800px) {
    z-index: 100;
  }

  background-color: #faf6f2;

  cursor: url(/icons/cursor-circle-on-white@1x.png) 9 9, pointer !important;
  cursor: -webkit-image-set(
        url(/icons/cursor-circle-on-white@1x.png) 1x,
        url(/icons/cursor-circle-on-white@2x.png) 2x
      )
      9 9,
    pointer !important;

  & a {
    cursor: url(/icons/cursor-circle-on-white@1x.png) 9 9, pointer !important;
    cursor: -webkit-image-set(
          url(/icons/cursor-circle-on-white@1x.png) 1x,
          url(/icons/cursor-circle-on-white@2x.png) 2x
        )
        9 9,
      pointer !important;
  }

  & .splash-inner-container {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
  }

  & .image-container {
    position: relative;
    padding: 20px;

    height: calc(${(props) => props.height}px - 100px - 68px);
    overflow: hidden;

    @media (max-width: 800px) {
      height: calc(${(props) => props.height}px - 58px);
      padding: 0 15px 15px 15px;
    }

    & .two-splash-images-container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 20px;
      align-items: flex-end;

      overflow: hidden;

      height: calc(${(props) => props.height}px - 100px - 88px);

      @media (max-width: 800px) {
        height: 100%;
      }

      & .image-left-container {
        display: flex;
        align-content: flex-end;
        flex-direction: row;
        flex-wrap: wrap;

        position: relative;
        width: 100%;
        height: 100%;

        max-height: calc(${(props) => props.height}px - 100px - 249px);

        & img {
          max-height: calc(${(props) => props.height}px - 100px - 249px);
          object-position: top;
        }

        @media (max-width: 1000px) {
          max-height: 50vh;

          & img {
            max-height: 50vh;
          }
        }
      }

      & .image-right-container {
        position: relative;
        width: 100%;
        height: 100%;
        max-height: calc(${(props) => props.height}px - 100px - 88px);

        @media (max-width: 1400px) {
          max-height: 75vh;

          & img {
            max-height: 75vh;
          }
        }

        @media (max-width: 1300px) {
          max-height: 65vh;

          & img {
            max-height: 65vh;
          }
        }
      }

      & img {
        width: 100%;
        height: 100%;

        object-fit: cover;
      }

      @media (max-width: 800px) {
        display: block;
        grid-template-columns: unset;
        grid-column-gap: unset;

        & .image-left-container {
          max-height: calc(${(props) => props.height}px - 58px);

          & img {
            max-height: calc(${(props) => props.height}px - 58px);
          }
        }

        & .image-right-container {
          display: none;
        }
      }
    }
  }

  & .menu-container {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: 20px;

    padding: 20px;

    & .logo {
      grid-column: 1 / 4;

      & h1 {
        font-size: 20px;
        line-height: 24px;
        letter-spacing: 0.1em;

        & span {
          letter-spacing: 0.04em;
        }

        color: #d0a790;

        @media (max-width: 1440px) {
          font-size: 18px;
          line-height: 24px;
        }
      }
    }

    & nav {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      grid-column: 8 / 13;

      @media (max-width: 1195px) {
        grid-column: 6 / 13;

        & ol {
          margin: 0 0 0 auto;
        }
      }

      @media (max-width: 910px) {
        grid-column: 5 / 13;
      }

      & ol {
        display: flex;
        flex-direction: row;

        & li {
          margin: 0 35px 0 0;

          transition: color 250ms ease;

          @media (hover: hover) and (pointer: fine) {
            &:hover {
              color: #dbaf96;
            }
          }
        }
      }
    }

    @media (max-width: 800px) {
      display: none;
    }
  }
`;

const Index = ({ data }) => {
  const height = use100vh();
  const [animateLogo, setAnimateLogo] = useState(false);
  const [animateFooter, setAnimateFooter] = useState(false);

  const triggerAnimation = (link) => {
    setAnimateLogo(true);

    const timer = setTimeout(() => {
      navigate(link);
    }, 750);
    return () => clearTimeout(timer);
  };

  const links = data.prismicMenus.data.header_links.map((link, index) => (
    <li
      key={`single_header_menu_item_${index}`}
      onClick={() => triggerAnimation(link.links.document.url)}
    >
      {link.links.document.data.title.text}
    </li>
  ));

  const images = data.prismicSplash.data.body.map((content, index) => {
    if (content.slice_type === "two_splash_images") {
      return (
        <div
          key={`single_splash_image_${index}`}
          className="two-splash-images-container"
        >
          <div className="image-left-container">
            {content.primary.image_left.fluid !== null && (
              <img
                srcSet={content.primary.image_left.fluid.srcSet}
                src={content.primary.image_left.fluid.src}
                alt={content.primary.image_left.alt}
                loading={`lazy`}
                onContextMenu={(e) => e.preventDefault()}
                draggable="false"
              />
            )}
          </div>

          <div className="image-right-container">
            {content.primary.image_right.fluid !== null && (
              <img
                srcSet={content.primary.image_right.fluid.srcSet}
                src={content.primary.image_right.fluid.src}
                alt={content.primary.image_right.alt}
                loading={`lazy`}
                onContextMenu={(e) => e.preventDefault()}
                draggable="false"
              />
            )}
          </div>
        </div>
      );
    }
  });

  return (
    <>
      <PageSeo seoTitle="RAW Editions" seoImage={null} seoText={null} />

      <Page>
        <SplashContainer height={height}>
          {/* <div className={`logo-container ${animateLogo ? `active` : ``}`}>
            <SplashR /> <SplashA /> <SplashW />
          </div> */}

          {/* <AnimatedLogo animateLogo={animateLogo} /> */}

          <div className="splash-inner-container">
            <div
              className="image-container"
              onClick={() => triggerAnimation(`/artists/`)}
            >
              {images}
            </div>

            <div className="menu-container">
              <div className="logo">
                <h1>
                  RAW <span>Editions</span>
                </h1>
              </div>
              <nav>
                <ol>{links}</ol>
              </nav>
            </div>
          </div>
        </SplashContainer>
      </Page>
    </>
  );
};

export const query = graphql`
  {
    prismicSplash {
      data {
        body {
          ... on PrismicSplashDataBodySingleImage {
            id
            slice_type
            primary {
              image {
                alt
                dimensions {
                  height
                  width
                }
                fluid(imgixParams: { ar: "1:1" }) {
                  src
                  srcSet
                  aspectRatio
                }
              }
            }
          }
          ... on PrismicSplashDataBodyTwoSplashImages {
            id
            slice_type
            primary {
              image_left {
                alt
                dimensions {
                  height
                  width
                }
                fluid {
                  src
                  srcSet
                  aspectRatio
                }
              }
              image_right {
                alt
                dimensions {
                  height
                  width
                }
                fluid {
                  src
                  srcSet
                  aspectRatio
                }
              }
            }
          }
        }
      }
    }
    prismicMenus {
      data {
        header_links {
          links {
            document {
              ... on PrismicOurStory {
                id
                url
                data {
                  title {
                    text
                  }
                }
              }
              ... on PrismicArtists {
                id
                url
                data {
                  title {
                    text
                  }
                }
              }
              ... on PrismicContact {
                id
                url
                data {
                  title {
                    text
                  }
                }
              }
              ... on PrismicShop {
                id
                url
                data {
                  title {
                    text
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default withPrismicPreview(Index);
