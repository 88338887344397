import React, { useContext, useEffect, useState } from "react";
import { graphql, Link } from "gatsby";
import { PrismicRichText } from "@prismicio/react";
import styled from "styled-components";

// Previews
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

// SEO
import { PageSeo } from "../components/seo/seo";

// Context
import { PageType } from "../components/context/page-type";

// Icons
import { ProductToggle } from "../components/icons/product-icons";

const Page = styled.div`
  margin: 0 -20px;

  & .header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;

    height: 75px;

    transition: 249ms background-color ease;
    background-color: #e9cfc0;

    z-index: 1;

    @media (max-width: 800px) {
      height: 56px;
    }
  }

  & .details-container {
    padding: 15px 20px;

    @media (max-width: 800px) {
      padding: 5px 15px;
    }

    white-space: break-spaces;
    border-bottom: 1px solid #a8b8c1;

    &.open {
      padding: 15px 20px 42px 20px;

      @media (max-width: 800px) {
        padding: 5px 15px 15px 15px;
      }

      & button {
        color: #91a8b7;
        margin: 0 0 30px 0;

        @media (max-width: 800px) {
          margin: 0 0 15px 0;
        }

        & svg {
          & path {
            &.vertical {
              opacity: 0;
            }
          }
        }
      }

      & .text-container {
        display: block;

        @media (max-width: 800px) {
          margin: 0 0 10px 0;
        }
      }
    }

    & .text-container {
      max-width: 810px;
      width: 100%;

      display: none;

      & p {
        font-size: 16px;
        line-height: 22px;

        padding: 0 0 0 21px;

        & strong {
          font-size: 12px;
          line-height: 22px;

          @media (max-width: 800px) {
            font-size: 10px;
            line-height: 14px;
          }
        }

        & a {
          transition: 250ms color ease;

          @media (hover: hover) and (pointer: fine) {
            &:hover {
              color: #91a8b7;
            }
          }
        }

        &:first-of-type {
          margin-top: 0;
        }

        &:last-of-type {
          margin-bottom: 0;
        }

        @media (max-width: 1440px) {
          font-size: 14px;
          line-height: 20px;
        }

        @media (max-width: 800px) {
          padding: 0 0 0 18px;
        }

        @media (max-width: 500px) {
          font-size: 10px;
          line-height: 14px;
        }
      }
    }

    & button {
      display: flex;
      align-items: center;

      font-size: 20px;
      line-height: 28px;

      transition: 250ms color ease;

      @media (hover: hover) and (pointer: fine) {
        &:hover {
          color: #91a8b7;

          & svg {
            & path {
              stroke: #91a8b7;
            }
          }
        }
      }

      & span {
        padding: 0 0 0 12px;
      }

      & svg {
        & path {
          transition: 250ms opacity ease, 250ms stroke ease;
        }

        @media (max-width: 500px) {
          width: 6px;
          height: auto;
        }
      }

      @media (max-width: 1440px) {
        font-size: 16px;
        line-height: 22px;
      }

      @media (max-width: 500px) {
        font-size: 10px;
        line-height: 14px;
      }
    }
  }
`;

const Support = ({ data, location }) => {
  const [pageType, setPageType] = useContext(PageType);
  const [openSupportIndex, setOpenSupportIndex] = useState(null);

  useEffect(() => {
    setPageType({
      page: `support`,
      background: `#E9CFC0`,
      banner: true,
      bannerBackground: `#E9CFC0`,
      fillColor: `#91A8B7`,
      hoverColor: `#91A8B7`,
      underlineColor: `#000`,
    });
  }, []);

  useEffect(() => {
    if (
      location !== null &&
      location !== undefined &&
      location !== `undefined`
    ) {
      if (
        location.state !== null &&
        location.state !== undefined &&
        location.state !== `undefined`
      ) {
        if (location.state.open !== undefined) {
          setOpenSupportIndex(data.prismicSupport.data.section.length - 1);
        }
      }
    }
  }, [location]);

  const content = data.prismicSupport.data.section.map(
    (section, outerIndex) => (
      <div
        key={`single_support_line_${outerIndex}`}
        className={`details-container ${openSupportIndex === outerIndex ? `open` : ``
          }`}
      >
        <button
          onClick={() => {
            if (openSupportIndex === outerIndex) {
              setOpenSupportIndex(null);
            } else {
              setOpenSupportIndex(outerIndex);
            }
          }}
        >
          <ProductToggle />
          <span>{section.section_title.text}</span>
        </button>

        <div className="text-container">
          <PrismicRichText
            field={section.section_text.richText}
            components={{
              paragraph: ({ children, index }) => (
                <p className="details">
                  {children}
                </p>
              ),
            }}
          />
        </div>
      </div>
    )
  );

  return (
    <>
      <PageSeo
        seoTitle="Support | RAW Editions"
        seoImage={null}
        seoText={null}
      />

      <Page>
        <div className="header" />
        {content}
      </Page>
    </>
  );
};

export const query = graphql`
  {
    prismicSupport {
      _previewable
      data {
        section {
          section_title {
            text
          }
          section_text {
            richText
          }
        }
      }
    }
  }
`;

export default withPrismicPreview(Support);
