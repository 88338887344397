import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { use100vh } from "react-div-100vh";
import Modal from "react-modal";
import { RemoveScroll } from "react-remove-scroll";
import { useKeyPressEvent } from "react-use";

// Icons
import { CloseFiltersIcon } from "../icons/close-filters-icon";
import { Arrow } from "../icons/arrows";

const LightboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  background-color: #e9cfc0;

  z-index: 100;

  height: 100%;
  width: 100%;
  overflow-y: scroll;

  padding: 195px 20px 20px 20px;

  @media (max-width: 800px) {
    padding: 90px 15px 15px 15px;
  }

  & .container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: space-between;

    min-height: calc(100% - 40px);
    width: 100%;

    & .grid-12 {
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      grid-column-gap: 20px;

      width: 100%;

      @media (max-width: 800px) {
        grid-template-columns: repeat(6, 1fr);
      }

      & .heading {
        grid-column: 1 / 13;
        margin: 0 0 30px 0;

        @media (max-width: 800px) {
          grid-column: 1 / 7;
          margin: 0 0 14px 0;
        }

        & p {
          font-size: 16px;
          line-height: 20px;
          letter-spacing: 0.04em;

          margin: 0;

          @media (max-width: 1440px) {
            font-size: 14px;
          }
        }
      }

      & .filter-section {
        grid-column: 1 / 10;
        margin: 0 0 1em 0;

        display: grid;
        grid-template-columns: repeat(10, 1fr);
        grid-column-gap: 20px;

        @media (max-width: 800px) {
          grid-column: 1 / 7;

          grid-template-columns: repeat(6, 1fr);
          grid-column-gap: 15px;
        }

        & .filter-title {
          grid-column: 1 / 11;

          display: grid;
          grid-template-columns: repeat(10, 1fr);
          grid-column-gap: 20px;

          @media (max-width: 800px) {
            grid-column: 1 / 7;
            grid-template-columns: repeat(6, 1fr);
            grid-column-gap: 15px;
          }
        }

        & .filters-container {
          grid-column: 2 / 10;

          @media (max-width: 800px) {
            grid-column: 1 / 7;
          }
        }

        &.is-open {
          & .toggle-filter-section {
            & svg {
              & path {
                stroke: #658ba5;
              }
            }

            & button {
              color: #658ba5;
            }
          }
        }

        & button {
          margin: 0;
          text-align: left;

          @media (max-width: 800px) {
            font-size: 13px;
            line-height: 18px;
            letter-spacing: 0.04em;
          }

          & span.maximum {
            font-size: 16px;

            @media (max-width: 1440px) {
              font-size: 14px;
            }

            @media (max-width: 500px) {
              font-size: 8px;
            }
          }
        }

        & .toggle-filter-section {
          grid-column: 1 / 11;

          display: grid;
          grid-template-columns: repeat(10, 1fr);
          grid-column-gap: 20px;
          align-items: center;

          width: 100%;

          @media (max-width: 800px) {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            grid-column: 1 / 7;
            grid-template-columns: unset;
            grid-column-gap: unset;
          }

          & svg {
            width: 65px;
            height: auto;

            & path {
              transition: 250ms stroke ease;
              stroke-width: 1.5;
            }

            @media (max-width: 800px) {
              width: 35px;
            }
          }

          & .button-container {
            grid-column: span 9;

            & button {
              transition: 250ms color ease;

              cursor: url(/icons/cursor-circle-on-buff@1x.png) 9 9, pointer !important;
              cursor: -webkit-image-set(
                    url(/icons/cursor-circle-on-buff@1x.png) 1x,
                    url(/icons/cursor-circle-on-buff@2x.png) 2x
                  )
                  9 9,
                pointer !important;
            }

            @media (max-width: 800px) {
              grid-column: unset;

              margin: 0 0 0 15px;
            }
          }

          @media (hover: hover) and (pointer: fine) {
            &:hover {
              & svg {
                & path {
                  stroke: #658ba5;
                }
              }

              & button {
                color: #658ba5;
              }
            }
          }
        }

        & .filters-container {
          & ol {
            &.column-4 {
              column-count: 4;
              column-gap: 20px;

              @media (max-width: 800px) {
                column-count: 2;
                column-gap: 15px;
              }
            }

            & li {
              @media (max-width: 800px) {
                font-size: 13px;
                line-height: 18px;
                letter-spacing: 0.04em;
              }

              & button {
                transition: 250ms color ease;

                cursor: url(/icons/cursor-circle-on-buff@1x.png) 9 9, pointer !important;
                cursor: -webkit-image-set(
                      url(/icons/cursor-circle-on-buff@1x.png) 1x,
                      url(/icons/cursor-circle-on-buff@2x.png) 2x
                    )
                    9 9,
                  pointer !important;

                @media (hover: hover) and (pointer: fine) {
                  &:hover {
                    color: #658ba5;
                  }
                }
              }

              &.active {
                & button {
                  color: #658ba5;
                }
              }
            }
          }
        }
      }
    }
  }

  & .buttons-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    width: 100%;

    @media (max-width: 800px) {
      justify-content: space-between;
    }

    & .left-column {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      margin: 0 50px 0 0;
    }

    & .right-column {
      & svg {
        margin: 0 15px 0 0;
      }

      @media (max-width: 800px) {
        & button {
          margin: 0;
        }
      }
    }

    & button {
      height: 50px;
      min-width: 115px;

      padding: 0 15px;
      margin: 0 20px 0 0;

      line-height: 43px;
      text-align: center;

      border: 1px solid #000000;
      transition: 250ms border-color ease, 250ms background-color ease;

      cursor: url(/icons/cursor-circle-on-buff@1x.png) 9 9, pointer !important;
      cursor: -webkit-image-set(
            url(/icons/cursor-circle-on-buff@1x.png) 1x,
            url(/icons/cursor-circle-on-buff@2x.png) 2x
          )
          9 9,
        pointer !important;

      @media (hover: hover) and (pointer: fine) {
        &:hover {
          border-color: #87a3b4;
          background-color: #87a3b4;
        }
      }

      @media (max-width: 800px) {
        height: 30px;
        min-width: 70px;

        line-height: 27px;

        padding: 0 9px;
        margin: 0 15px 0 0;

        & svg {
          width: 7px;
          height: auto;
        }
      }
    }
  }
`;

Modal.setAppElement("#___gatsby");

export const Filters = ({
  artists,
  isFiltersOpen,
  setIsFiltersOpen,
  filterSettings,
  setFilterSettings,
  // activeArtistFilter,
  // activePriceFilter,
  // hideSoldItems,
  // updateArtistFilter,
  // updatePriceFilter,
  // updateSoldItemsFilter,
}) => {
  const [isArtistsFilterOpen, setIsArtistsFilterOpen] = useState(true);
  const [isPriceFilterOpen, setIsPriceFilterOpen] = useState(false);

  const height = use100vh();

  useEffect(() => {
    if (typeof window !== undefined) {
      document.addEventListener("keydown", (event) => onEscape(event));
    }
  }, [isFiltersOpen]);

  const onEscape = (event) => {
    if (event.keyCode === 27 && isFiltersOpen === true) {
      document.body.style.overflow = "";
      setIsFiltersOpen(false);
    }
  };

  return (
    <RemoveScroll>
      <Modal
        isOpen={isFiltersOpen}
        className="artwork-lighbox-modal"
        contentLabel={`Filters`}
        shouldFocusAfterRender={true}
      >
        <LightboxContainer height={height}>
          <div className="container">
            <div className="grid-12">
              <div className="heading">
                <p>Filtering</p>
              </div>

              <div
                className={`filter-section ${
                  isArtistsFilterOpen ? `is-open` : ``
                }`}
              >
                <div className="toggle-filter-section">
                  <Arrow />

                  <div className="button-container">
                    <button
                      onClick={() =>
                        setIsArtistsFilterOpen(!isArtistsFilterOpen)
                      }
                    >
                      <span className="button-text">Artist</span>
                    </button>
                  </div>
                </div>

                <div className="filters-container">
                  {isArtistsFilterOpen && (
                    <ol className="column-4">{artists}</ol>
                  )}
                </div>
              </div>

              <div
                className={`filter-section ${
                  isPriceFilterOpen ? `is-open` : ``
                }`}
              >
                <div className="filter-title">
                  <div className="toggle-filter-section">
                    <Arrow />

                    <div className="button-container">
                      <button
                        onClick={() => setIsPriceFilterOpen(!isPriceFilterOpen)}
                      >
                        <span className="button-text">
                          Price <span className="maximum">(maximum)</span>
                        </span>
                      </button>{" "}
                    </div>
                  </div>
                </div>

                <div className="filters-container">
                  <ol>
                    {isPriceFilterOpen && (
                      <>
                        <li
                          className={
                            filterSettings.activePriceFilter === 1000
                              ? `active`
                              : ``
                          }
                        >
                          <button
                            onClick={() =>
                              setFilterSettings({
                                ...filterSettings,
                                activePriceFilter: 1000,
                              })
                            }
                          >
                            £1,000
                          </button>
                        </li>
                        <li
                          className={
                            filterSettings.activePriceFilter === 2000
                              ? `active`
                              : ``
                          }
                        >
                          <button
                            onClick={() =>
                              setFilterSettings({
                                ...filterSettings,
                                activePriceFilter: 2000,
                              })
                            }
                          >
                            £2,000
                          </button>
                        </li>
                        <li
                          className={
                            filterSettings.activePriceFilter === 5000
                              ? `active`
                              : ``
                          }
                        >
                          <button
                            onClick={() =>
                              setFilterSettings({
                                ...filterSettings,
                                activePriceFilter: 5000,
                              })
                            }
                          >
                            £5,000
                          </button>
                        </li>
                        <li
                          className={
                            filterSettings.activePriceFilter === 10000
                              ? `active`
                              : ``
                          }
                        >
                          <button
                            onClick={() =>
                              setFilterSettings({
                                ...filterSettings,
                                activePriceFilter: 10000,
                              })
                            }
                          >
                            £10,000
                          </button>
                        </li>
                        <li
                          className={
                            filterSettings.activePriceFilter === 15000
                              ? `active`
                              : ``
                          }
                        >
                          <button
                            onClick={() =>
                              setFilterSettings({
                                ...filterSettings,
                                activePriceFilter: 15000,
                              })
                            }
                          >
                            £15,000
                          </button>
                        </li>
                        <li
                          className={
                            filterSettings.activePriceFilter === 25000
                              ? `active`
                              : ``
                          }
                        >
                          <button
                            onClick={() =>
                              setFilterSettings({
                                ...filterSettings,
                                activePriceFilter: 25000,
                              })
                            }
                          >
                            £25,000
                          </button>
                        </li>
                        <li
                          className={
                            filterSettings.activePriceFilter === 50000
                              ? `active`
                              : ``
                          }
                        >
                          <button
                            onClick={() =>
                              setFilterSettings({
                                ...filterSettings,
                                activePriceFilter: 50000,
                              })
                            }
                          >
                            £50,000
                          </button>
                        </li>
                      </>
                    )}
                  </ol>
                </div>
              </div>

              <div
                className={`filter-section ${
                  filterSettings.hideSoldItems ? `is-open` : ``
                }`}
              >
                <div className="filter-title">
                  <div className="toggle-filter-section">
                    <Arrow />

                    <div className="button-container">
                      <button
                        onClick={() =>
                          setFilterSettings({
                            ...filterSettings,
                            hideSoldItems: !filterSettings.hideSoldItems,
                          })
                        }
                      >
                        <span className="button-text">Hide sold items</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="buttons-container">
              <div className="left-column">
                <button
                  onClick={() => {
                    if (typeof window !== `undefined`) {
                      window.scrollTo(0, 0);
                    }

                    setIsFiltersOpen(false);
                  }}
                >
                  Apply filter
                </button>

                <button
                  onClick={() => {
                    setFilterSettings({
                      activeArtistFilter: [],
                      activePriceFilter: null,
                      hideSoldItems: false,
                    });
                  }}
                >
                  Clear filter
                </button>
              </div>

              <div className="right-column">
                <button onClick={() => setIsFiltersOpen(false)}>
                  <CloseFiltersIcon />
                  Close filter
                </button>
              </div>
            </div>
          </div>
        </LightboxContainer>
      </Modal>
    </RemoveScroll>
  );
};
