import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import addToMailchimp from "gatsby-plugin-mailchimp";

const EnquiryFormContainer = styled.div`
  & form {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: baseline;

    border-bottom: 1px solid ${(props) => props.underlineColor};

    line-height: 21px;

    & p {
      font-size: 16px;
      margin: 0;

      @media (max-width: 1440px) {
        font-size: 14px;
      }
    }

    & .field {
      width: calc(100% - 180px - 90px);

      & .form-message {
        font-size: 12px;
        line-height: 15px;

        padding: 3px 0;
      }

      & input {
        width: 100%;
        padding: 3px 0;
        margin: 0;

        font-size: 12px;
        line-height: 15px;

        border: 0;
        background-color: transparent;
      }
    }

    & button {
      font-size: 12px;
      line-height: 1;

      padding: 7px 0;
      transition: 250ms color ease;

      @media (hover: hover) and (pointer: fine) {
        &:hover {
          color: ${(props) => props.hoverColor};
        }
      }
    }
  }
`;

export const Mailchimp = ({ hoverColor, underlineColor }) => {
  // Form Setup
  const { register, handleSubmit } = useForm();
  const [formMessage, setFormMessage] = useState(null);

  const onSubmit = (data, e) => {
    e.preventDefault();

    addToMailchimp(data.email, {
      FNAME: " ",
      LNAME: " ",
      MMERGE5: " ",
    })
      .then((data) => {
        // I recommend setting data to React state
        // but you can do whatever you want (including ignoring this `then()` altogether)
        if (data.result === "error") {
          if (data.msg === "The email you entered is not valid.") {
            setFormMessage("Invalid Email");
          }

          if (data.msg.includes("already subscribed")) {
            setFormMessage("Thank you!");
            e.target.reset();
          }
        } else {
          setFormMessage("Thank you!");
          e.target.reset();
        }
      })
      .catch(() => {
        // unnecessary because Mailchimp only ever
        // returns a 200 status code
        // see below for how to handle errors
      });
  };

  useEffect(() => {
    if (formMessage !== null) {
      const closeForm = setTimeout(() => {
        setFormMessage(null);
      }, 4000);
      return () => clearTimeout(closeForm);
    }
  }, [formMessage]);

  return (
    <EnquiryFormContainer
      hoverColor={hoverColor}
      underlineColor={underlineColor}
    >
      <form
        name="mailchimp-footer-signup"
        method="POST"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="">
          <p>For all things RAW</p>
        </div>

        <div className="field">
          {formMessage !== null ? (
            <p className="form-message">{formMessage}</p>
          ) : (
            <>
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                aria-required="true"
                aria-label="Enter email address"
                placeholder={`Enter email address`}
                ref={register({ required: true })}
              />

              {/* <input
                hidden
                type="checkbox"
                id="gdpr_351853"
                name="gdpr[351853]"
                value="Y"
                ref={register({ required: true })}
              /> */}
            </>
          )}
        </div>

        <button type="submit">Sign up</button>
      </form>
    </EnquiryFormContainer>
  );
};
