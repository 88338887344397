import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";
import kebabCase from "lodash.kebabcase";

// Icons
import { MailchimpArrow, MobileArrow } from "../icons/arrows";

// Utils
import { formatPrice } from "../utils/format-price";

const Container = styled.div`
  & .recommended-title {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;

    margin: 0 0 55px 0;

    @media (max-width: 800px) {
      margin: 0 0 15px 0;
      // margin: 30px 0 15px 0;
    }

    & h3 {
      font-size: 20px;
      line-height: 44px;

      color: #dbaf96;

      margin: 0 30px 0 0;

      @media (max-width: 1440px) {
        font-size: 18px;
      }

      @media (max-width: 500px) {
        font-size: 13px;
        line-height: 18px;

        margin: 0 10px 0 0;
      }
    }

    & svg {
      &.mobile-arrow {
        display: none;

        & path {
          fill: #dbaf96;
        }
      }

      @media (max-width: 800px) {
        width: 36px;
        height: auto;

        &.desktop-arrow {
          display: none;
        }

        &.mobile-arrow {
          display: block;
        }
      }
    }
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 100px 1fr 100px 1fr;

  margin: 0 0 90px 0;

  @media (max-width: 1440px) {
    grid-template-columns: 1fr 50px 1fr 50px 1fr;
  }

  @media (max-width: 800px) {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 15px;
    grid-row-gap: 60px;

    margin: 0 0 120px 0;
  }
`;

const RelatedArtworkContainer = styled.div`
  &:nth-of-type(1) {
    grid-column: 1;
  }
  &:nth-of-type(2) {
    grid-column: 3;
  }
  &:nth-of-type(3) {
    grid-column: 5;
  }

  @media (max-width: 800px) {
    &:nth-of-type(1),
    &:nth-of-type(2),
    &:nth-of-type(3) {
      grid-column: span 1;
    }

    &:nth-of-type(3) {
      display: none;
    }
  }

  height: 100%;

  & a {
    display: grid;
    grid-template-rows: auto 4em;
    height: 100%;

    & > div {
      width: 100%;
    }
  }

  & .aspect-ratio-container {
    max-height: calc(${(props) => props.height}px - 250px);

    @media (max-height: 950px) {
      max-height: calc(${(props) => props.height}px - 400px);
    }

    display: flex;
    align-items: center;
    justify-content: center;

    & .gatsby-image-wrapper {
      max-height: calc(${(props) => props.height}px - 250px);

      @media (max-height: 950px) {
        max-height: calc(${(props) => props.height}px - 400px);
      }

      & img {
        width: 100%;
        height: 100%;

        max-height: calc(${(props) => props.height}px - 250px);

        @media (max-height: 950px) {
          max-height: calc(${(props) => props.height}px - 400px);
        }

        object-fit: contain !important;
        object-position: center;

        cursor: url(/icons/cursor-plus-on-white@1x.png) 9 9, pointer !important;
        cursor: -webkit-image-set(
              url(/icons/cursor-plus-on-white@1x.png) 1x,
              url(/icons/cursor-plus-on-white@2x.png) 2x
            )
            9 9,
          pointer !important;
      }
    }
  }

  & .artwork-details {
    margin: 20px 0 0 0;

    @media (max-width: 500px) {
      margin: 5px 0 0 0;
    }

    & p {
      font-size: 14px;
      line-height: 19px;

      margin: 0;

      &.artist-title {
        letter-spacing: 0.08em;
      }

      @media (max-width: 500px) {
        font-size: 10px;
        line-height: 14px;
      }
    }
  }

  & .sold-out {
    & .sold-out-span {
      display: block;
      margin: 10px 0 0 0;

      width: 9px;
      height: 9px;

      border-radius: 100%;
      background-color: #f1624e;

      @media (max-width: 800px) {
        margin: 3px 0 0 0;

        width: 7px;
        height: 7px;
      }
    }
  }
`;

export const RelatedProducts = ({
  relatedProductOne,
  relatedProductTwo,
  relatedProductThree,
  relatedProductOverride,
  productTags,
  products,
  getArtistName,
  getPriceOnApplication,
  height,
  product,
}) => {
  const overrideProducts = products
    .filter((product) => {
      if (relatedProductOverride) {
        if (relatedProductThree !== null) {
          if (product.shopifyId === relatedProductThree) {
            return product;
          }
        }

        if (relatedProductTwo !== null) {
          if (product.shopifyId === relatedProductTwo) {
            return product;
          }
        }

        if (relatedProductOne !== null) {
          if (product.shopifyId === relatedProductOne) {
            return product;
          }
        }
      }
    })
    .reverse();

  const artistOtherWorks = products
    .filter((product) => {
      if (product.tags.some((r) => productTags.indexOf(r) >= 0)) {
        return product;
      }
    })
    .filter((singleProduct) => singleProduct.handle !== product.handle);

  const fillerProducts = products
    .filter((singleProduct) => singleProduct.handle !== product.handle)
    .filter((singleProduct) =>
      singleProduct.tags.some((r) => product.tags.indexOf(r) === -1)
    );

  const productsArray = [
    ...overrideProducts,
    ...artistOtherWorks,
    ...fillerProducts,
  ];

  const finalProductsArray = Array.from(new Set(productsArray));

  const relatedArtworks = finalProductsArray
    .filter((product, index) => {
      if (index <= 2) {
        return product;
      }
    })
    .map((artwork, index) => {
      return (
        <RelatedArtworkContainer
          className="related-artwork-container"
          height={height}
          key={`single_related_artwork_${index}_${product.handle}`}
        >
          <Link to={`/${kebabCase(getArtistName(artwork))}/${artwork.handle}/`}>
            <div
              className="aspect-ratio-container"
              onContextMenu={(e) => e.preventDefault()}
            >
              <GatsbyImage
                image={artwork.images[0].gatsbyImageData}
                alt={artwork.title}
                draggable="false"
              />
            </div>

            <div className="artwork-details">
              <p className="artwork-title">{artwork.title}</p>
              <p className="artist-title uppercase">
                {getArtistName(artwork) !== null && (
                  <>{getArtistName(artwork)}</>
                )}
              </p>
              <p className="sold-out">
                {artwork.totalInventory < 1 ? (
                  <span className="sold-out-span"></span>
                ) : (
                  <span>
                    {getPriceOnApplication(artwork) !== null ? (
                      <>
                        {getPriceOnApplication(artwork) === true ? (
                          `Price on Request`
                        ) : (
                          <>
                            {formatPrice(
                              artwork.priceRangeV2.minVariantPrice.currencyCode,
                              artwork.priceRangeV2.minVariantPrice.amount
                            )}
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        {formatPrice(
                          artwork.priceRangeV2.minVariantPrice.currencyCode,
                          artwork.priceRangeV2.minVariantPrice.amount
                        )}
                      </>
                    )}
                  </span>
                )}
              </p>
            </div>
          </Link>
        </RelatedArtworkContainer>
      );
    });

  return (
    <Container className="content recommended-container">
      <div className="recommended-title">
        <h3>RAW recommends</h3>
        <MailchimpArrow />
        <MobileArrow />
      </div>
      <Grid>{relatedArtworks}</Grid>
    </Container>
  );
};
