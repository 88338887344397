import React from "react";
import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`

html {
  body {
    opacity: 0;
  }
}

html.wf-active,
html.wf-inactive {
  body {
    opacity: 1;
  }
}

body {
  font-family: "Citerne-Light", "Helvetica Neue", "Lucida Grande", sans-serif;
  font-weight: normal;
  font-style: normal;

  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.04em;

  text-shadow: 1px 1px 1px rgba(0, 0, 0, .004);
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  -webkit-tap-highlight-color: transparent;

  transition: 250ms opacity ease, 250ms background-color ease;

  color: #000;
  margin: 0;

  @media (max-width: 1440px) {
    font-size: 16px;
    line-height: 22px;
  }

  @media(max-width: 500px){
    font-size: 10px;
    line-height: 14px;
  }
}

:-webkit-autofill,
:-webkit-autofill:hover,
:-webkit-autofill:focus {
  background-color: inherit;
}

input {
  border-radius: 0;

  &:focus{
    outline: none;
  }
}

textarea{
  :focus{
    outline: none;
  }
}

h1,h2,h3,h4,h5{
  font-family: "Citerne-Light", "Helvetica Neue", "Lucida Grande", sans-serif;
  font-weight: normal;

  margin: 0;
}

.uppercase {
  text-transform: uppercase;
}

.small-text,
.small-text p {
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.04em;

  @media (max-width: 1440px) {
    font-size: 14px;
    line-height: 20px;
  }

  @media(max-width: 500px){
    font-size: 10px;
    line-height: 14px;
  }
}

/* Title 1 */
h1{
  font-size: 30px;
  line-height: 44px;
  letter-spacing: 0.08em;

  @media (max-width: 1440px) {
    font-size: 24px;
    line-height: 36px;
  }

  @media(max-width: 500px){
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.04em;
  }
}

/* Title 2 */
h2 {
  font-size: 30px;
  line-height: 32px;
  letter-spacing: 0.04em;
}

/* Heading 1 */
h3 {
  font-size: 30px;
  line-height: 32px;
  letter-spacing: 0.04em;
}

/* Heading 2 */
h4 {
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.04em;
}

/* Heading 3 */
h5 {
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.04em;
}

strong {
  font-weight: normal;
  font-style: normal;
}




/*--------------------------------------------------------------
  ## Media
  --------------------------------------------------------------*/

video,
img {
  width: 100%;
  margin: 0;

  display: block;
}

/*--------------------------------------------------------------
  ## Buttons
  --------------------------------------------------------------*/

  button{
    background-color: transparent;
    border:0;
    padding:0;
    margin:0;

    appearance:none;
    letter-spacing: 0.04em;

    color: inherit;
    font-weight: normal;

    &:focus,
    &:hover{
      outline: none;
    }
  }

/*--------------------------------------------------------------
  ## Links
  --------------------------------------------------------------*/
a {
  color: inherit;
  text-decoration: none;

  position: relative;
}

a:visited {
  color: inherit;
}

a:hover,
a:focus,
a:active {
  color: inherit;
}

a:hover,
a:active,
a:focus {
  outline: 0;
}

/*--------------------------------------------------------------
  ## Text
  --------------------------------------------------------------*/

p {
  margin: 1em 0;
}

ul, ol, li{
  list-style: none;
  padding: 0;
  margin: 0;
}

/*--------------------------------------------------------------
  ## Box Sizing / Layout
  --------------------------------------------------------------*/

div {
  box-sizing: border-box;
}

/*--------------------------------------------------------------
  ## Input Placeholders
  --------------------------------------------------------------*/

::-webkit-input-placeholder{
  color: rgba(0, 0, 0, 0.4);
  letter-spacing: 0.04em;
}

::-moz-placeholder{
  color: rgba(0, 0, 0, 0.4);
  letter-spacing: 0.04em;
}

:-ms-input-placeholder{
  color: rgba(0, 0, 0, 0.4);
  letter-spacing: 0.04em;
}

:-moz-placeholder {
  color: rgba(0, 0, 0, 0.4);
  letter-spacing: 0.04em;
}


/*--------------------------------------------------------------
  ## React Modal
--------------------------------------------------------------*/
.ReactModalPortal{
  position: fixed;

  top:0;
  left:0;
  right:0;
  bottom:0;

  z-index: 100;
}

.ReactModalPortal-gallery{
  position: fixed;

  top:0;
  left:0;
  right:0;
  bottom:0;

  z-index: 100000;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 250ms ease;
  background-color: #fff;
}

.ReactModal__Overlay--after-open{
  opacity: 1;
}

.ReactModal__Overlay--before-close{
  opacity: 0;
}

.ReactModal__Content{
  width: 100%;
  height: 100%;
}
`;

export default GlobalStyle;
