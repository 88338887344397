import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import { useStaticQuery, graphql, Link } from "gatsby";

// Context
import { StoreContext } from "../context/store-context";

// Components
import SearchIndex from "../search/search-index";

// Icons
import { Logo, MobileLogo } from "../icons/logo";
import { CartIcon, OpenIcon } from "../icons/menu-icons";

// Logo
import { AnimatedLogo } from "./animated-logo";

// Utils
import kebabcase from "lodash.kebabcase";

const HeaderWrapper = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  padding: 20px;

  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 20px;

  align-items: flex-start;

  z-index: 20000;

  &.logo-small {
    & .logo {
      grid-column: 1 / 4;
      z-index: 1;

      & svg {
        display: block;
        width: auto;
        // height: 35px;

        & path {
          transition: 249ms fill ease;
        }
      }
    }
  }

  &.logo-large {
    & .logo {
      grid-column: 1 / 13;
      z-index: 1;
    }
  }

  & nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    grid-column: 8 / 13;

    @media (max-width: 1195px) {
      grid-column: 6 / 13;

      & ol {
        &.menu-links {
          margin: 0 0 0 auto;
        }
      }
    }

    @media (max-width: 910px) {
      grid-column: 5 / 13;
    }

    @media (max-width: 800px) {
      grid-column: unset;

      justify-content: flex-end;
    }

    &.search-open {
    }

    & ol {
      display: flex;
      flex-direction: row;

      &.icon-links {
        align-items: baseline;
        z-index: 1;

        & .search-icon {
          margin: 0 15px 0 0;

          & path {
            transition: 250ms stroke ease;
          }

          @media (hover: hover) and (pointer: fine) {
            &:hover {
              & path {
                stroke: ${(props) => props.hoverColor};
              }
            }
          }
        }

        & .cart-icon {
          margin: 0;

          & svg {
            position: relative;

            & .dot {
              width: 8px;
              height: 8px;
              background: #f1624e;
              border-radius: 100%;
            }

            & path,
            & rect {
              transition: 250ms stroke ease;
            }
          }

          @media (hover: hover) and (pointer: fine) {
            &:hover {
              & path,
              & rect {
                stroke: ${(props) => props.hoverColor};
              }
            }
          }
        }
      }

      & li {
        margin: 0 35px 0 0;

        & a {
          transition: color 250ms ease;

          &.current {
            color: ${(props) => props.hoverColor};
          }

          @media (hover: hover) and (pointer: fine) {
            &:hover {
              color: ${(props) => props.hoverColor};
            }
          }
        }
      }
    }
  }

  &.our-story {
    & .logo {
      & svg {
        display: none;
      }
    }

    & nav {
      & ol {
        & li {
          & a {
            &.current {
              color: #f9e0d2;
            }

            @media (hover: hover) and (pointer: fine) {
              &:hover {
                color: #f9e0d2;
              }
            }
          }
        }
      }
    }
  }

  &.contact {
    & nav {
      & ol {
        & li {
          & a {
            &.current {
              color: #e9cfc0;
            }

            @media (hover: hover) and (pointer: fine) {
              &:hover {
                color: #e9cfc0;
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 910px) {
    & .logo {
      & svg {
        display: block;
        height: 28px;
      }
    }
  }

  @media (max-width: 800px) {
    display: flex;
    grid-template-columns: unset;
    grid-column-gap: unset;

    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    padding: 15px;

    & nav {
      & ol {
        &.icon-links {
          & li {
            margin: 0;

            &:first-of-type {
              margin: 0 15px 0 0;
            }
          }
        }
      }
    }

    & .mobile-menu-links {
      display: block;
      flex-direction: unset;

      position: absolute;
      top: 0;
      left: 0;
      right: 0;

      background-color: #e9cfc0;
      padding: 190px 15px 15px 15px;

      & li {
        font-size: 20px;
        line-height: 32px;

        & a {
          &.current {
            color: #87a3b4 !important;
          }
        }

        &.search-icon {
          margin: 20px 0 0 0;
        }

        &.additional-link {
          font-size: 13px;
          line-height: 22px;

          margin: 15px 0 0 0;
        }

        &.bottom-menu-item {
          margin: 0;
        }
      }
    }
  }
`;

export const Header = ({
  location,
  pageBackgroundColor,
  fillColor,
  isDesktop,
  setFilterSettings,
  pageType,
}) => {
  const { checkout } = useContext(StoreContext);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  const [animateLogo, setAnimateLogo] = useState(
    location.pathname === `/` ? false : true
  );
  const [logoSize, setLogoSize] = useState(
    location.pathname === `/` ? `logo-large` : `logo-small`
  );
  const [showNav, setShowNav] = useState(
    location.pathname === `/` ? false : true
  );

  useEffect(() => {
    if (location.pathname === `/`) {
      setAnimateLogo(false);
      setLogoSize(`logo-large`);
      setShowNav(false);
    } else {
      setAnimateLogo(true);

      const displayNav = setTimeout(() => {
        setShowNav(true);
        setLogoSize(`logo-small`);
      }, 550);
      return () => clearTimeout(displayNav);
    }
  }, [location]);

  const data = useStaticQuery(graphql`
    {
      prismicMenus {
        data {
          header_links {
            links {
              document {
                ... on PrismicOurStory {
                  id
                  url
                  data {
                    title {
                      text
                    }
                  }
                }
                ... on PrismicArtists {
                  id
                  url
                  data {
                    title {
                      text
                    }
                  }
                }
                ... on PrismicContact {
                  id
                  url
                  data {
                    title {
                      text
                    }
                  }
                }
                ... on PrismicShop {
                  id
                  url
                  data {
                    title {
                      text
                    }
                  }
                }
              }
            }
          }
        }
      }
      prismicFairs {
        data {
          visible_on_website
        }
      }
    }
  `);

  const links = data.prismicMenus.data.header_links
    .filter((link) => link.links.document !== null)
    .map((link, index) => {
      if (link.links.document.url === `/shop/`) {
        return (
          <li key={`single_header_menu_item_${index}`}>
            <Link
              to={link.links.document.url}
              activeClassName="current"
              onClick={() => {
                setFilterSettings({
                  activeArtistFilter: [],
                  activePriceFilter: null,
                  hideSoldItems: false,
                });
              }}
            >
              {link.links.document.data.title.text}
            </Link>
          </li>
        );
      } else if (
        link.links.document.data.title.text === `Artists` &&
        pageType === `artist`
      ) {
        return (
          <li key={`single_header_menu_item_${index}`}>
            <Link to={link.links.document.url} className="current">
              {link.links.document.data.title.text}
            </Link>
          </li>
        );
      } else {
        return (
          <li key={`single_header_menu_item_${index}`}>
            <Link to={link.links.document.url} activeClassName="current">
              {link.links.document.data.title.text}
            </Link>
          </li>
        );
      }
    });

  useEffect(() => {
    setIsMenuOpen(false);
  }, [location]);

  //  animateLogo ? `logo-small` : `logo-large`;

  return (
    <HeaderWrapper
      backgroundColor={pageBackgroundColor}
      hoverColor={fillColor}
      className={`${kebabcase(location.pathname)} ${logoSize}`}
    >
      {isDesktop ? (
        <>
          {/* <Logo fillColor={fillColor} /> */}

          <AnimatedLogo
            animateLogo={animateLogo}
            setAnimateLogo={setAnimateLogo}
            showNav={showNav}
            setShowNav={setShowNav}
            fillColor={fillColor}
            logoSize={logoSize}
            setLogoSize={setLogoSize}
            location={location.pathname}
          />

          {showNav && (
            <nav>
              <>
                {!isSearchOpen ? (
                  <>
                    <ol className="menu-links">{links}</ol>
                    <ol className="icon-links">
                      <SearchIndex
                        isSearchOpen={isSearchOpen}
                        setIsSearchOpen={setIsSearchOpen}
                        underlineColor={fillColor}
                        hoverColor={fillColor}
                        location={location}
                      />

                      <CartIcon dot={checkout.lineItems.length > 0} />
                    </ol>
                  </>
                ) : (
                  <SearchIndex
                    isSearchOpen={isSearchOpen}
                    setIsSearchOpen={setIsSearchOpen}
                    underlineColor={fillColor}
                    hoverColor={fillColor}
                    location={location}
                  />
                )}
              </>
            </nav>
          )}
        </>
      ) : (
        <>
          <MobileLogo fillColor={isMenuOpen ? `#87A3B4` : fillColor} />

          <nav>
            <ol className="icon-links">
              <li>
                <CartIcon dot={checkout.lineItems.length > 0} />
              </li>

              <li>
                <button onClick={() => setIsMenuOpen(!isMenuOpen)}>
                  <OpenIcon rotate={isMenuOpen ? 45 : 0} />
                </button>
              </li>
            </ol>

            {isMenuOpen && (
              <ol className="mobile-menu-links">
                {links}

                <li className="additional-link">
                  <Link to={`/support/`}>Support</Link>
                </li>
                <li className="additional-link bottom-menu-item">
                  <Link to={`/our-alphabet/`}>Our Alphabet</Link>
                </li>
                {data.prismicFairs.data.visible_on_website === true && (
                  <li className="additional-link bottom-menu-item">
                    <Link to={`/fairs/`}>Fairs</Link>
                  </li>
                )}

                <li className="search-icon">
                  <SearchIndex
                    isSearchOpen={isSearchOpen}
                    setIsSearchOpen={setIsSearchOpen}
                    underlineColor={fillColor}
                    hoverColor={fillColor}
                    location={location}
                  />
                </li>
              </ol>
            )}
          </nav>
        </>
      )}
    </HeaderWrapper>
  );
};
