import React from "react";

export const RLogo = () => (
  <svg
    className="r-logo"
    width="202"
    height="284"
    viewBox="0 0 202 284"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25.6717 137.17V21.9246H127.822C159.424 21.9246 176.115 41.0521 176.115 77.2728C176.115 116.46 156.544 137.17 119.632 137.17H25.6717ZM196.845 222.354C194.868 170.283 161.091 157.371 139.26 155.365V145.165C149.501 144.692 159.599 142.574 169.168 138.893C189.332 128.694 201.787 109.679 201.787 77.3011C201.787 25.9931 177.301 0 129.065 0H0V283.748H25.6717V159.123H108.759C140.022 159.123 170.58 174.464 170.665 221.506V283.663H196.647L196.845 222.354Z"
      fill="#E9CFC0"
    />
  </svg>
);

export const ALogo = () => (
  <svg
    className="a-logo"
    width="284"
    height="236"
    viewBox="0 0 284 236"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M186.619 174.737L76.7965 136.582C34.0155 123.233 13.1481 123.968 13.1481 123.968V112.06C13.1481 112.06 34.0155 112.824 76.7965 99.4458L186.506 61.291L186.619 174.737ZM0 101.539V134.461L284 236V210.12L209.409 182.883V53.0321L284 25.7948V0L0 101.539Z"
      fill="#E9CFC0"
    />
  </svg>
);

export const WLogo = () => (
  <svg
    className="w-logo"
    width="322"
    height="284"
    viewBox="0 0 322 284"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M296.792 0.0281947L250.024 206.616C244.466 227.349 243.16 248.992 246.183 270.243H232.683C235.703 248.955 234.173 227.268 228.193 206.616L176.821 0.0281947H144.541L93.1694 206.616C87.1896 227.268 85.659 248.955 88.6789 270.243H75.1794C78.1856 248.991 76.8793 227.352 71.3386 206.616L24.5138 0.0281947H0L64.9559 283.409H98.5918L149.088 76.7924C159.086 40.487 154.736 13.8158 154.736 13.8158H166.457C164.481 34.9856 166.444 56.3383 172.246 76.7924L222.799 283.381H256.435L321.391 0L296.792 0.0281947Z"
      fill="#E9CFC0"
    />
  </svg>
);
