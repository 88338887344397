import React, { useEffect } from "react";
import { PrismicRichText } from "@prismicio/react";
import styled from "styled-components";

const SingleMobileLetter = styled.div`
  & .letters-content-container {
    margin: 40px 0 0 0;
  }

  & .active-items-container {
    max-width: 450px;

    @media (max-width: 500px) {
      max-width: 230px;
    }
  }

  & h1 {
    font-size: 20px;
    line-height: 30px;

    text-align: center;
    color: #87a3b4;
  }

  & h3 {
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.04em;
    text-align: center;

    writing-mode: vertical-rl;
    text-orientation: mixed;

    width: 100%;
    max-width: calc(450px / 2);
    margin: 0 0 50px 0;

    @media (max-width: 800px) {
      font-size: 14px;
      margin: 0 0 20px 0;
    }

    @media (max-width: 500px) {
      font-size: 9px;
      line-height: 15px;

      max-width: calc(230px / 2);
    }
  }

  & p {
    margin: 0;
    max-width: 450px;

    font-size: 14px;
    line-height: 19px;

    @media (max-width: 500px) {
      font-size: 10px;
      line-height: 14px;
    }
  }

  &:nth-of-type(odd) {
    & .active-items-container {
      &:nth-of-type(odd) {
        margin: 0 0 40px auto;
      }

      &:nth-of-type(even) {
        margin: 0 0 40px 0;
      }
    }
  }

  &:nth-of-type(even) {
    & .active-items-container {
      &:nth-of-type(odd) {
        margin: 0 0 40px 0;
      }

      &:nth-of-type(even) {
        margin: 0 0 40px auto;
      }
    }
  }
`;

export const OurAlphabetMobile = ({ data, location }) => {
  useEffect(() => {
    if (
      location !== null &&
      location !== undefined &&
      location !== `undefined`
    ) {
      if (
        location.state !== null &&
        location.state !== undefined &&
        location.state !== `undefined`
      ) {
        if (
          location.state.letter !== undefined &&
          location.state.letter !== null
        ) {
          document
            .getElementById(location.state.letter.toLowerCase())
            .scrollIntoView({
              behavior: "smooth",
              block: "start",
            });
        }
      }
    }
  }, [location]);

  const content = data.prismicGlossary.data.body.map((letter, index) => {
    const letters = letter.items.map((item, innerIndex) => (
      <div
        className="active-items-container"
        key={`single_inner_item_${innerIndex}_${index}_${letter.primary.letter.text}`}
      >
        <PrismicRichText
          field={item.section_title.richText}
          className="title"
        />

        <PrismicRichText field={item.text.richText} />
      </div>
    ));

    return (
      <SingleMobileLetter
        className="single-mobile-letter"
        key={`single_mobile_letter_${index}_${letter.id}`}
      >
        <h1 id={letter.primary.letter.text.toLowerCase()}>
          {letter.primary.letter.text}
        </h1>

        <div className="letters-content-container">{letters}</div>
      </SingleMobileLetter>
    );
  });

  return <>{content}</>;
};
