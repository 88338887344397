import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { PrismicRichText } from "@prismicio/react";
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies";
import CookieConsent from "react-cookie-consent";
import styled from "styled-components";

const CookieContainer = styled.div`
  & .cookie-container {
    position: fixed;
    bottom: 0;
    left: 0;

    background-color: #f5e9e2;

    z-index: 1000;
    max-width: 450px;
    width: 100%;
    height: auto;

    padding: 13px 20px 20px 20px;

    & .top-row {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;

      width: 100%;

      & p {
        margin: 0 0 1em 0;

        & a {
          transition: 250ms color ease;

          @media (hover: hover) and (pointer: fine) {
            &:hover {
              color: #91A8B7;
            }
          }
        }
      }

      & button {
        height: 19px;
      }
    }

    & .button-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-content: flex-end;

      width: 100%;

      & a {
        line-height: 48px;

        border: 1px solid #000000;
      }

      & button {
        line-height: 43px;
        border: 1px solid #000000;
        cursor: pointer;

        &.cookie-button {
          order: 1;
          margin: 0 25px 0 0;
          background-color: #a8b8c1;
          border: 1px solid #a8b8c1;
        }

        &#rcc-decline-button {
          order: 2;
        }
      }

      & a,
      & button {
        height: 50px;
        width: 130px;

        padding: 0;
        margin: 0 20px 0 0;

        text-align: center;

        transition: 250ms border-color ease, 250ms background-color ease;
        box-sizing: border-box;

        @media (hover: hover) and (pointer: fine) {
          &:hover {
            border-color: #e9cfc0;
            background-color: #e9cfc0;
          }
        }

        @media (max-width: 800px) {
          height: 30px;
          min-width: 70px;

          line-height: 27px;

          padding: 0 9px;
          margin: 0 15px 0 0;

          & svg {
            width: 7px;
            height: auto;
          }
        }
      }
    }

    /* & p {
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.04em;

      margin: 0;

      & a {
        text-decoration: underline;
        text-decoration-thickness: from-font;
        text-underline-offset: 1px;
      }
    }

    @media (max-width: 1100px) {
      align-items: flex-start;
    }

    @media (max-width: 768px) {
      padding: 15px 20px;

      font-size: 11px;
      line-height: 14px;

      display: block;
      grid-template-columns: unset;
      grid-column-gap: unset;
    }

    & .cookie-text {
      max-width: 850px;

      @media (max-width: 768px) {
        max-width: 500px;
        margin: 0 0 20px 0;
      }
    }

    & .cookie-container {
      display: inline-block;

      @media (max-width: 768px) {
        margin: 0 10px 0 0;
      }
    }

    & .content-container {
      color: #dbaf96;

      @media (max-width: 768px) {
        margin: 0 0 20px 0;
      }
    }

    & .cookie-text > p {
      margin: 0;
      color: #dbaf96;
    }

    & .button-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }

    & button {
      font-size: 14px;
      line-height: 1;
      display: block;

      width: fit-content;

      height: 30px;
      padding: 0 20px;

      background: #dbaf96;
      border: 1px solid #dbaf96;
      border-radius: 60px;
      color: #fffcfa;
      transition: 250ms background-color ease, 250ms color ease;

      &.cookie-button {
        order: 1;
        margin: 0 25px 0 0;
      }

      &#rcc-decline-button {
        order: 2;
      }

      @media (max-width: 1280px) {
        font-size: 12px;
        line-height: 1;
      }

      @media (max-width: 960px) {
        font-size: 10px;
        height: 22px;
        line-height: 1;
      }

      @media (max-width: 500px) {
        padding: 0 10px;

        &.cookie-button {
          margin: 0 15px 0 0;
        }
      }

      @media (hover: hover) and (pointer: fine) {
        &:hover,
        &:focus,
        &:focus-within {
          color: #dbaf96;
          background-color: #fff;
        }
      }
    }

    & a.privacy-link {
      border: 1px solid #fff;
      transition: 250ms all ease;

      text-align: center;
      text-transform: uppercase;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0.075em;
      padding: 3px 10px;

      transition: 250ms all ease;

      color: #dbaf96;

      cursor: pointer;
      display: inline-block;

      @media (max-width: 768px) {
        font-size: 10px;
        padding: 2px 5px;
      }

      @media (hover: hover) and (pointer: fine) {
        &:hover,
        &:focus,
        &:focus-within {
          color: #dcede9;
          background-color: #fff;
        }
      }
    } */
  }
`;

export const Cookies = ({ location }) => {
  const data = useStaticQuery(graphql`
    {
      prismicMenus {
        data {
          cookie_text {
            richText
          }
        }
      }
    }
  `);

  // if (location.pathname)

  return (
    <CookieContainer
      className={location.pathname === `/` ? `homepage` : `page`}
    >
      <CookieConsent
        location="bottom"
        buttonText="Accept"
        enableDeclineButton
        declineButtonText="Decline"
        cookieName="raw-editions-google-analytics"
        containerClasses="cookie-container"
        contentClasses="content-container"
        buttonWrapperClasses="button-container"
        buttonClasses="cookie-button"
        disableStyles={true}
        onAccept={() => {
          initializeAndTrack(location);
        }}
        expires={150}
      >
        <div className="top-row">
          <PrismicRichText
            field={data.prismicMenus.data.cookie_text.richText}
            components={{
              hyperlink: ({ node, children }) => {
                if (node.data.link_type === `Document`) {
                  return <Link to={`/${node.data.uid}`} state={{ open: true }}>{children}</Link>
                } else {
                  return <a target="_blank" href={node.data.url}>{children}</a>
                }
              },
            }}
          />

          {/* <button onClick={() => setIsPopUpOpen(false)}>
            <Close />
          </button> */}
        </div>
      </CookieConsent>
    </CookieContainer >
  );
};
