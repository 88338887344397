import * as React from "react";
import {
  PrismicPreviewProvider,
  componentResolverFromMap,
} from "gatsby-plugin-prismic-previews";

// Context
import { StoreProvider } from "./src/components/context/store-context";

// Prismic
import { linkResolver } from "./src/components/link-resolver/linkResolver";

// Pages
import Artist from "./src/pages/{PrismicArtist.url}";
import Contact from "./src/pages/contact";
import Fairs from "./src/pages/fairs";
import OurAlphabet from "./src/pages/our-alphabet";
import Index from "./src/pages/index";
import Artists from "./src/pages/artists";
import OurStory from "./src/pages/our-story";
import Shop from "./src/pages/shop";
import Support from "./src/pages/support";
import Product from "./src/templates/product";

export const wrapRootElement = ({ element }) => (
  <PrismicPreviewProvider
    repositoryConfigs={[
      {
        repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
        linkResolver,
        componentResolver: componentResolverFromMap({
          artist: Artist,
          artists: Artists,
          contact: Contact,
          fairs: Fairs,
          glossary: OurAlphabet,
          index: Index,
          our_story: OurStory,
          shop: Shop,
          support: Support,
          product: Product,
        }),
      },
    ]}
  >
    <StoreProvider>{element}</StoreProvider>
  </PrismicPreviewProvider>
);
