import React from "react";
import styled from "styled-components";

// Components
import { Header } from "./header";
import { Footer } from "./footer";
import Transition from "../transition/transition";

const Main = styled.main`
  flex: 1;

  margin: 190px 20px 90px 20px;

  &.page-search,
  &.page-our-story {
    margin: 190px 20px 20px 20px;
  }

  @media (max-width: 800px) {
    margin: 90px 15px 30px 15px;
  }
`;

export const App = ({
  location,
  children,
  pageType,
  isDesktop,
  showFooter,
  setFilterSettings,
}) => {
  return (
    <>
      <Header
        location={location}
        pageBackgroundColor={pageType.background}
        fillColor={pageType.fillColor}
        isDesktop={isDesktop}
        setFilterSettings={setFilterSettings}
        pageType={pageType.page}
      />

      <Transition location={location}>
        <Main className={`page-${pageType.page}`}>{children}</Main>
      </Transition>

      {showFooter &&
        pageType.banner === true &&
        pageType.page !== `404` &&
        pageType.page !== `artwork` &&
        pageType.page !== `cart` && (
          <Footer
            location={location}
            bgColor={pageType.bannerBackground}
            hoverColor={pageType.hoverColor}
            underlineColor={pageType.underlineColor}
            showMailchimp={pageType.page !== `contact`}
          />
        )}
    </>
  );
};
