import React, { useEffect } from "react";
import { PrismicRichText } from "@prismicio/react";

// Utils
import { splitToChunks } from "../utils/split-to-chunks";

export const OurAlphabetDesktop = ({
  data,
  activeIndex,
  setActiveIndex,
  location,
  isOdddOrEven,
}) => {
  function alphabetPosition(text) {
    var result = "";
    for (var i = 0; i < text.length; i++) {
      var code = text.toUpperCase().charCodeAt(i);
      if (code > 64 && code < 91) result += code - 64 + " ";
    }

    return result.slice(0, result.length - 1);
  }

  useEffect(() => {
    if (
      location !== null &&
      location !== undefined &&
      location !== `undefined`
    ) {
      if (
        location.state !== null &&
        location.state !== undefined &&
        location.state !== `undefined`
      ) {
        if (location.state.letter !== undefined) {
          setActiveIndex(alphabetPosition(location.state.letter) - 1);
        }
      }
    }
  }, [location]);

  const content = data.prismicGlossary.data.body.map((letter, index) => {
    return letter.items.map((item, innerIndex) => (
      <div
        className="active-items-container"
        key={`single_inner_item_${innerIndex}_${index}_${letter.primary.letter.text}`}
      >
        <PrismicRichText
          field={item.section_title.richText}
          className="title"
        />

        <PrismicRichText field={item.text.richText} />
      </div>
    ));
  });

  const letters = data.prismicGlossary.data.body.map((letter, index) => (
    <li
      key={`single_glossary_letter_${index}`}
      className={activeIndex === index ? `active` : `inactive`}
    >
      <button onClick={() => setActiveIndex(index)}>
        {letter.primary.letter.text}
      </button>
    </li>
  ));

  // Split in group of 2 items
  let splitContent;

  if (activeIndex !== null) {
    splitContent = splitToChunks(content[activeIndex], 2);
  } else {
    splitContent = ``;
  }

  return (
    <>
      {activeIndex !== null && (
        <div className={`active-items left index-${isOdddOrEven(activeIndex)}`}>
          <div
            className="inner-container"
            id={
              isOdddOrEven(activeIndex) === `even`
                ? `container-${activeIndex}`
                : ``
            }
          >
            {splitContent[0]}
          </div>
        </div>
      )}

      <ol>{letters}</ol>

      {activeIndex !== null && (
        <div
          className={`active-items right index-${isOdddOrEven(activeIndex)}`}
        >
          <div
            className="inner-container"
            id={
              isOdddOrEven(activeIndex) === `odd`
                ? `container-${activeIndex}`
                : ``
            }
          >
            {splitContent[1]}
          </div>
        </div>
      )}
    </>
  );
};
