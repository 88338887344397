import React, { useRef, useState } from "react";
import styled from "styled-components";
import { PrismicRichText } from "@prismicio/react";

// Slideshow
import { Fade } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

// Components
import { AspectRatioImageContainer } from "../containers/aspect-ratio-image-container";
import { ImageOrientation } from "../utils/image-orientation";

const GalleryContainer = styled.div`
  position: relative;
  z-index: 1;

  // border: 1px solid black;

  width: 100%;
  height: 100%;
  max-height: 100%;

  & > div,
  & .react-slideshow-container,
  & .react-slideshow-fade-wrapper,
  & .react-slideshow-fade-images-wrap,
  & .react-slideshow-fade-images-wrap > div,
  & .single-image-container {
    position: relative;

    width: 100%;
    height: 100%;
  }

  @media (max-height: 800px) {
    & .aspect-ratio-container {
      max-height: 500px;

      & img {
        max-height: 500px;
      }
    }
  }

  & img {
    object-fit: contain !important;
    object-position: left;
    height: 100%;
  }

  & .image-button-container {
    overflow: hidden;

    &.portrait {
      position: relative;
      width: fit-content;
      height: 100%;
      // border: 1px solid red;

      & img {
        width: fit-content;
      }
    }

    &.landscape {
      position: relative;
      width: 100%;
      height: auto;
      // border: 1px solid blue;

      & img {
        width: 100%;
      }
    }
  }
`;

const Arrow = styled.button`
  position: absolute;
  top: 0;
  bottom: 0;

  width: 50%;

  z-index: 100;

  &.previous {
    left: 0;

    cursor: url(/icons/arrow-prev@1x.png) 50 0, w-resize !important;
    cursor: -webkit-image-set(
          url(/icons/arrow-prev@1x.png) 1x,
          url(/icons/arrow-prev@2x.png) 2x
        )
        50 0,
      w-resize !important;
  }

  &.next {
    right: 0;

    cursor: url(/icons/arrow-next@1x.png) 50 0, e-resize !important;
    cursor: -webkit-image-set(
          url(/icons/arrow-next@1x.png) 1x,
          url(/icons/arrow-next@2x.png) 2x
        )
        50 0,
      e-resize !important;
  }

  @media (hover: none) and (pointer: coarse) {
    display: none;
  }
`;

export const FairsGallery = ({ images, index, id }) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  // Slideshow Reference
  const slideRef = useRef();

  const content = images.map((image, innerIndex) => (
    <div className="single-slide">
      <AspectRatioImageContainer image={null} padding={75}>
        <div
          className={`image-button-container ${ImageOrientation(image.image)}`}
        >
          <img
            key={`single_${innerIndex}_image_${index}_${id}`}
            srcSet={image.image.fluid.srcSet}
            src={image.image.fluid.src}
            alt={image.image.alt}
            loading={`lazy`}
            onContextMenu={(e) => e.preventDefault()}
            draggable="false"
          />
        </div>
      </AspectRatioImageContainer>

      {image.image_caption.text.length >= 1 && (
        <div className="image-caption">
          <PrismicRichText field={image.image_caption.richText} />
        </div>
      )}
    </div>
  ));

  return (
    <GalleryContainer>
      {images.length > 1 && (
        <Arrow
          aria-label="Previous Slide"
          className={`previous`}
          onClick={() => {
            slideRef.current.goBack();
          }}
        />
      )}
      <Fade
        ref={slideRef}
        arrows={false}
        transitionDuration={0}
        pauseOnHover={false}
        autoplay={false}
        infinite={true}
        canSwipe={content.length > 1 ? true : false}
      >
        {content}
      </Fade>

      {images.length > 1 && (
        <Arrow
          aria-label="Next Slide"
          className={`next`}
          onClick={() => {
            slideRef.current.goNext();
          }}
        />
      )}
    </GalleryContainer>
  );
};
