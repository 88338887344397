import React, { useContext, useEffect, useState } from "react";
import { graphql } from "gatsby";
import { PrismicRichText } from "@prismicio/react";
import styled from "styled-components";

// Previews
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

// SEO
import { PageSeo } from "../components/seo/seo";

// Context
import { PageType } from "../components/context/page-type";

// Icons
import { RLogo, ALogo, WLogo } from "../components/icons/our-story-icons";

const Page = styled.div`
  // & .header {
  //   position: fixed;
  //   top: 0;
  //   left: 0;
  //   right: 0;

  //   height: 75px;

  //   background-color: #bdbfb7;

  //   z-index: 1;

  // @media (max-width: 800px) {
  //   height: 56px;
  // }
  // }

  & .r-logo {
    position: fixed;
    top: 20px;
    left: 20px;

    z-index: -1;

    @media (max-width: 1440px) {
      width: 150px;
      height: auto;
    }

    @media (max-width: 800px) {
      left: 15px;
    }

    @media (max-width: 500px) {
      width: 120px;
    }
  }

  & .a-logo {
    position: fixed;
    top: 50%;
    right: 20px;

    z-index: -1;

    @media (max-width: 1440px) {
      width: 210px;
      height: auto;
    }

    @media (max-width: 800px) {
      transform: translateY(-50%);
    }

    @media (max-width: 500px) {
      width: 170px;
    }
  }

  & .w-logo {
    position: fixed;
    left: 33.333%;
    bottom: 48px;

    z-index: -1;

    @media (max-width: 1440px) {
      width: 239px;
      height: auto;
    }

    @media (max-width: 1110px) {
      left: 20%;
      bottom: 20px;
    }

    @media (max-width: 800px) {
      left: 15px;
    }

    @media (max-width: 500px) {
      width: 193px;
    }
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 20px;

  @media (max-width: 800px) {
    grid-template-columns: repeat(6, 1fr);
    grid-column-gap: 15px;
  }

  & .text-container {
    margin: 0 0 75px 0;

    @media (max-height: 990px) {
      margin: 0 0 50px 0;
    }

    & .inner-text-container {
      max-width: 700px;

      @media (max-width: 1440px) {
        max-width: 610px;
      }
    }

    &:nth-of-type(odd) {
      grid-column: 2 / 12;

      @media (max-width: 1024px) {
        grid-column: 1 / 12;
      }

      @media (max-width: 800px) {
        grid-column: 1 / 6;
      }
    }

    &:nth-of-type(even) {
      grid-column: 2 / 12;

      @media (max-width: 1024px) {
        grid-column: 2 / 13;
      }

      @media (max-width: 800px) {
        grid-column: 2 / 7;
      }

      & .inner-text-container {
        margin: 0 0 0 auto;
      }
    }
  }
`;

// ContentContainer;

const OurStory = ({ data }) => {
  const [pageType, setPageType] = useContext(PageType);

  useEffect(() => {
    setPageType({
      page: `our-story`,
      background: `#BDBFB7`,
      banner: true,
      bannerBackground: `#BDBFB7`,
      fillColor: `#f9e0d2`,
      hoverColor: `#f9e0d2`,
      underlineColor: `#f9e0d2`,
    });
  }, []);

  const content = data.prismicOurStory.data.body.map((content, index) => {
    if (content.slice_type === `text`) {
      return (
        <div className="text-container" key={`single_text_container_${index}`}>
          <div className="inner-text-container">
            <PrismicRichText field={content.primary.text.richText} />
          </div>
        </div>
      );
    }
  });

  return (
    <>
      <PageSeo
        seoTitle="Our Story | RAW Editions"
        seoImage={null}
        seoText={null}
      />

      <Page>
        {/* <div className="header" /> */}
        <Grid>{content}</Grid>
        <RLogo /> <ALogo /> <WLogo />
      </Page>
    </>
  );
};

export const query = graphql`
  {
    prismicOurStory {
      _previewable
      data {
        body {
          ... on PrismicOurStoryDataBodyText {
            id
            slice_type
            primary {
              text {
                richText
              }
            }
          }
        }
      }
    }
  }
`;

export default withPrismicPreview(OurStory);
