import React, { useContext, useState, useEffect, useCallback } from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
import { use100vh } from "react-div-100vh";
import { useWindowScroll, useDebounce } from "react-use";
import { GatsbyImage } from "gatsby-plugin-image";

// SEO
import { PageSeo } from "../components/seo/seo";

// Context
import { StoreContext } from "../components/context/store-context";
import { PageType } from "../components/context/page-type";

// Components
import { RelatedProducts } from "../components/product/related-products";
import { Lightbox } from "../components/images/lightbox-gallery";
import { ShareButtons } from "../components/product/share-buttons";
import { Banner } from "../components/product/banner";

// Utils
import { formatPrice } from "../components/utils/format-price";

// Forms
import { ProductEnquiryForm } from "../components/forms/product-enquiry";

// Icons
import { ProductArrow, ProductToggle } from "../components/icons/product-icons";
import { MobileArrow } from "../components/icons/arrows";

const Page = styled.div`
  & .header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;

    height: 75px;

    transition: 249ms background-color ease;
    background-color: #fffcfa;

    z-index: 1;

    @media (max-width: 800px) {
      height: 56px;
    }
  }

  & .mobile-title {
    display: none;
    margin: 0 0 20px 0;

    & h2 {
      font-size: 14px;
      line-height: 17px;

      & .artist-title {
        color: #dbaf96;
      }
    }

    & .arrow {
      & svg {
        width: 37px;
        margin: 0 6px;
      }
    }

    @media (max-width: 800px) {
      display: block;

      & a {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
      }
    }
  }

  & .content-container {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: 20px;

    @media (max-width: 800px) {
      margin: 0 0 30px 0;
    }

    & .image-container:nth-of-type(odd) {
      grid-column: 1 / 13;

      display: grid;
      grid-template-columns: repeat(12, 1fr);
      grid-column-gap: 20px;

      margin: 0 0 150px 0;

      & .gatsby-image-wrapper {
        grid-column: 7 / 13;

        & img {
          object-position: right;
        }
      }

      @media (max-width: 800px) {
        margin: 0 0 20px 0;

        & .gatsby-image-wrapper {
          grid-column: 3 / 13;
        }
      }
    }

    & .image-container:nth-of-type(even) {
      grid-column: 1 / 13;

      display: grid;
      grid-template-columns: repeat(12, 1fr);
      grid-column-gap: 20px;

      margin: 0 0 150px 0;

      & .gatsby-image-wrapper {
        grid-column: 1 / 7;
      }

      @media (max-width: 800px) {
        margin: 0 0 20px 0;

        & .gatsby-image-wrapper {
          grid-column: 1 / 11;
        }
      }
    }

    & .image-container.last-image {
      grid-column: 1 / 13;
      order: 100;

      display: grid;
      grid-template-columns: repeat(12, 1fr);
      grid-column-gap: 20px;

      margin: 0 0 150px 0;

      & .gatsby-image-wrapper {
        grid-column: 1 / 13;

        & img {
          object-fit: contain !important;
          object-position: center;

          max-height: calc(${(props) => props.height}px - 250px);

          @media (max-width: 500px) {
            max-height: 100%;
          }
        }
      }

      @media (max-width: 800px) {
        margin: 0 0 20px 0;
      }
    }

    & .text-content-container {
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      grid-column-gap: 20px;

      grid-column: 1 / 13;

      & .text-container {
        margin: 0 0 150px 0;

        @media (max-width: 800px) {
          margin: 30px 0;
        }

        &.left {
          grid-column: 2 / 10;

          & .text,
          & .credit {
            max-width: 925px;
            width: 100%;
          }

          @media (max-width: 800px) {
            grid-column: 1 / 13;
          }
        }

        &.right {
          grid-column: 4 / 11;

          & .text,
          & .credit {
            max-width: 925px;
            width: 100%;
          }

          @media (max-width: 800px) {
            grid-column: 1 / 13;
          }
        }

        & .text {
          & p {
            &:first-of-type {
              margin-top: 0;
            }

            &:last-of-type {
              margin-bottom: 0;
            }

            @media (max-width: 500px) {
              font-size: 11px;
              line-height: 15px;
            }
          }
        }

        & .credit {
          & p {
            font-size: 16px;
            line-height: 28px;

            margin: 0;

            @media (max-width: 1440px) {
              font-size: 14px;
            }

            @media (max-width: 500px) {
              font-size: 9px;
              line-height: 15px;
            }
          }
        }
      }
    }

    & .order-1 {
      order: 1;
    }

    & .order-2 {
      order: 2;
    }

    & .order-3 {
      order: 3;
    }

    & .order-4 {
      order: 4;
    }

    & .order-5 {
      order: 5;
    }

    & .order-6 {
      order: 6;
    }

    & .order-7 {
      order: 7;
    }

    & .order-8 {
      order: 8;
    }

    & .order-9 {
      order: 9;
    }

    & .order-10 {
      order: 10;
    }

    & .order-11 {
      order: 11;
    }

    & .order-12 {
      order: 12;
    }

    & .order-13 {
      order: 13;
    }

    & .order-14 {
      order: 14;
    }

    & .order-15 {
      order: 15;
    }

    & .order-16 {
      order: 16;
    }
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 20px;

  &.introduction {
    margin: 0 0 330px 0;

    @media (max-width: 800px) {
      margin: 0 0 50px 0;
    }
  }

  & .product-details {
    grid-column: 1 / 13;

    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: 20px;

    & .title-bar {
      grid-column: 1 / 13;

      margin: 0 0 20px 0;

      & h1 {
        font-size: 20px;
        line-height: 28px;

        & span {
          &.artwork-title {
            margin: 0 35px 0 0;
            letter-spacing: 0.04em;
          }
        }

        & a {
          transition: 250ms color ease;

          @media (hover: hover) and (pointer: fine) {
            &:hover {
              color: #dbaf96;
            }
          }
        }

        @media (max-width: 1440px) {
          font-size: 18px;
          line-height: 24px;
        }

        @media (max-width: 500px) {
          font-size: 14px;
          line-height: 18px;
        }
      }

      @media (max-width: 800px) {
        margin: 10px 0 20px 0;

        order: 2;

        & h1 {
          & span {
            display: block;
          }
        }
      }
    }

    & .left-column {
      grid-column: 1 / 7;

      @media (max-width: 800px) {
        grid-column: 1 / 13;
        order: 1;
      }

      & .image-container {
        & img {
          object-position: left;
        }
      }
    }

    & .right-column {
      grid-column: 8 / 13;

      @media (max-width: 800px) {
        grid-column: 1 / 13;
        order: 3;
      }

      & .text-container {
        max-width: 455px;

        font-size: 16px;
        line-height: 22px;

        @media (max-width: 1440px) {
          font-size: 14px;
          line-height: 20px;
        }

        @media (max-width: 500px) {
          font-size: 11px;
          line-height: 15px;
        }

        & h2,
        & p,
        & p[style] {
          font-size: 16px;
          line-height: 22px;

          white-space: pre-line;

          &:first-of-type {
            margin-top: 0;
          }

          &:last-of-type {
            margin-bottom: 0;
          }

          @media (max-width: 1440px) {
            font-size: 14px;
            line-height: 20px;
          }

          @media (max-width: 500px) {
            font-size: 11px;
            line-height: 15px;
          }
        }
      }

      & .dropdowns-container,
      & .sell-container {
        max-width: 455px;

        margin: 30px 0 0 0;

        @media (max-width: 800px) {
          margin: 15px 0 0 0;
        }

        & .details {
          white-space: pre-line;

          & .text-container {
            display: none;
            padding: 0 0 0 21px;

            @media (max-width: 500px) {
              padding: 0 0 0 19px;
            }
          }

          & p {
            font-size: 16px;
            line-height: 22px;
            letter-spacing: 0.04em;

            &:first-of-type {
              margin-top: 0;
            }

            &:last-of-type {
              margin-bottom: 0;
            }

            @media (max-width: 1440px) {
              font-size: 14px;
              line-height: 20px;
            }

            @media (max-width: 500px) {
              font-size: 11px;
              line-height: 15px;
            }
          }

          & button {
            display: flex;
            align-items: center;

            // cursor: pointer;

            font-size: 13px;
            line-height: 20px;

            @media (max-width: 500px) {
              font-size: 11px;
              line-height: 16px;
            }

            transition: 250ms color ease;

            @media (hover: hover) and (pointer: fine) {
              &:hover {
                color: #dbaf96;

                & svg {
                  & path {
                    stroke: #dbaf96;
                  }
                }
              }
            }

            & span {
              padding: 0 0 0 12px;
            }

            & svg {
              & path {
                transition: 250ms opacity ease, 250ms stroke ease;
              }

              @media (max-width: 500px) {
                width: 6px;
                height: auto;
              }
            }
          }

          &.open {
            margin: 0 0 1em 0;

            & .text-container {
              display: block;
            }

            & svg {
              & path {
                &.vertical {
                  opacity: 0;
                }
              }
            }
          }
        }
      }

      & .sell-container {
        margin: 0;

        & button {
          transition: 250ms color ease;

          @media (hover: hover) and (pointer: fine) {
            &:hover {
              color: #dbaf96;
            }
          }
        }
      }

      & .links-container {
        & ol {
          & li {
            font-size: 13px;
            line-height: 20px;

            @media (max-width: 500px) {
              font-size: 11px;
              line-height: 16px;
            }

            & button,
            & a {
              transition: 250ms color ease;

              & svg {
                & path {
                  transition: 250ms fill ease;
                }

                @media (max-width: 500px) {
                  width: 6px;
                  height: auto;
                }
              }

              & span {
                padding: 0 0 0 8px;

                @media (max-width: 800px) {
                  padding: 0 0 0 9px;
                }
              }

              @media (hover: hover) and (pointer: fine) {
                &:hover {
                  color: #dbaf96;

                  & svg {
                    & path {
                      fill: #dbaf96;
                    }
                  }
                }
              }
            }
          }
        }
      }

      & .share-links-container {
        display: flex;
        flex-direction: row;
        align-items: center;

        margin: 30px 0 0 0;

        @media (max-width: 800px) {
          margin: 15px 0 0 0;
        }

        & .email-icon {
          height: 16px;
          line-height: 16px !important;

          @media (max-width: 500px) {
            height: 11px;
            line-height: 11px !important;

            & svg {
              height: 11px;
              width: 11px;
            }
          }
        }

        & button {
          &.facebook-icon,
          &.pinterest-icon {
            height: 22px;
            line-height: 22px !important;
          }

          & svg {
            & path {
              transition: 250ms fill ease;
            }
          }

          @media (hover: hover) and (pointer: fine) {
            &:hover {
              & svg {
                & path {
                  fill: #000;
                }
              }
            }
          }
        }

        & svg {
          margin: 0 20px 0 0;
        }

        @media (max-width: 500px) {
          & button {
            &.facebook-icon,
            &.pinterest-icon {
              height: 11px;
              line-height: 11px !important;

              & svg {
                height: 11px;
                width: auto;
                margin: 0 10px 0 0;
              }
            }
          }
        }
      }

      & .enquiry-button-container {
        margin: 10px 0 0 0;

        & button {
          color: #dbaf96;
          max-width: 320px;

          font-size: 16px;
          line-height: 20px;

          transition: 250ms color ease;
          text-align: left;

          @media (hover: hover) and (pointer: fine) {
            &:hover {
              color: #000;
            }
          }

          @media (max-width: 1440px) {
            font-size: 14px;
          }

          @media (max-width: 500px) {
            font-size: 11px;
            line-height: 15px;
          }
        }
      }
    }
  }

  & .image-container {
    width: fit-content;

    @media (max-width: 800px) {
      margin: 0 auto;
    }
  }
`;

const ImageContainer = styled.div`
  & img {
    cursor: url(/icons/cursor-plus-on-white@1x.png) 12 12, pointer !important;
    cursor: -webkit-image-set(
          url(/icons/cursor-plus-on-white@1x.png) 1x,
          url(/icons/cursor-plus-on-white@2x.png) 2x
        )
        12 12,
      pointer !important;
  }

  &:not(.last-image) {
    & .gatsby-image-wrapper {
      max-height: calc(${(props) => props.height}px - 313px);

      @media (max-width: 500px) {
        max-height: 100%;
      }

      & img {
        width: 100%;
        height: 100%;

        max-height: calc(${(props) => props.height}px - 313px);

        @media (max-width: 500px) {
          max-height: 100%;
        }

        object-fit: contain !important;
      }
    }
  }

  &.last-image.image-number-0 {
    & .gatsby-image-wrapper {
      max-height: calc(${(props) => props.height}px - 313px);

      @media (max-width: 500px) {
        max-height: 100%;
      }

      & img {
        width: 100%;
        height: 100%;

        max-height: calc(${(props) => props.height}px - 313px);

        @media (max-width: 500px) {
          max-height: 100%;
        }

        object-fit: contain !important;
        object-position: left;
      }
    }
  }
`;

const Product = ({ data, location }) => {
  const product = data.shopifyProduct;
  const productTags = product.tags;

  // Image Sizing
  const height = use100vh();

  // Product Enquiry
  const [isProductEnquiryOpen, setIsProductEnquiryOpen] = useState(false);
  const [formType, setFormType] = useState(null);

  // Lightbox
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [activeSlideNumber, setActiveSlideNumber] = useState(0);

  const [pageType, setPageType] = useContext(PageType);

  // Banner
  const [isBannerVisible, setIsBannerVisible] = useState(false);
  const { x, y } = useWindowScroll();

  const [, cancel] = useDebounce(
    () => {
      if (y > 160) {
        setIsBannerVisible(true);
      } else {
        setIsBannerVisible(false);
      }
    },
    25,
    [y]
  );

  useEffect(() => {
    setPageType({
      page: `artwork`,
      background: `#FFFCFA`,
      banner: true,
      bannerBackground: `#E9CFC0`,
      fillColor: `#DBAF96`,
      hoverColor: `#f9e0d2`,
      underlineColor: `#FBF7F5`,
    });
  }, []);

  const getArtistName = (artwork) => {
    const metafields =
      artwork.metafields.length >= 1 ? artwork.metafields : null;

    const artistMetafield =
      metafields !== null
        ? metafields
            .filter((field) => field.key === "artist")
            .map((field) => field.value)
        : null;

    if (artistMetafield !== null && artistMetafield.length >= 1) {
      return artistMetafield[0];
    } else {
      return null;
    }
  };

  const getMetafield = (artwork, fieldKey) => {
    const metafields =
      artwork.metafields.length >= 1 ? artwork.metafields : null;

    const metafield =
      metafields !== null
        ? metafields
            .filter((field) => field.key === fieldKey)
            .map((field) => field.value)
        : null;

    if (metafield !== null && metafield.length >= 1) {
      return metafield[0];
    } else {
      return null;
    }
  };

  const getPriceOnApplication = (artwork) => {
    const metafields =
      artwork.metafields.length >= 1 ? artwork.metafields : null;

    const priceOfApplicationMetafield =
      metafields !== null
        ? metafields
            .filter((field) => field.key === "price_on_application")
            .map((field) => field.value)
        : null;

    if (
      priceOfApplicationMetafield !== null &&
      priceOfApplicationMetafield.length >= 1
    ) {
      if (priceOfApplicationMetafield[0] === "true") {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const {
    options,
    variants,
    variants: [initialVariant],
    priceRangeV2,
    title,
    description,
    images,
    images: [firstImage],
  } = product;

  const { client } = useContext(StoreContext);

  const [variant, setVariant] = useState({ ...initialVariant });

  const productVariant =
    client.product.helpers.variantForOptions(product, variant) || variant;

  const [available, setAvailable] = useState(productVariant.availableForSale);

  const checkAvailablity = useCallback(
    (productId) => {
      client.product.fetch(productId).then((fetchedProduct) => {
        const result =
          fetchedProduct?.variants.filter(
            (variant) => variant.id === productVariant.storefrontId
          ) ?? [];

        if (result.length > 0) {
          setAvailable(result[0].available);
        }
      });
    },
    [productVariant.storefrontId, client.product]
  );

  useEffect(() => {
    checkAvailablity(product.storefrontId);
  }, [productVariant.storefrontId, checkAvailablity, product.storefrontId]);

  const price = formatPrice(
    priceRangeV2.minVariantPrice.currencyCode,
    variant.price
  );

  const productImages = product.images.map((image, index) => {
    const imageOrderNumber = index < 3 ? index : index + 2;
    const lastImage = index === product.images.length - 1 ? true : false;

    return (
      <ImageContainer
        height={height}
        className={`image-container order-${imageOrderNumber} image-number-${index} ${
          lastImage ? `last-image` : ``
        }`}
        key={`single_image_container_${index}_${image.id}`}
        onClick={() => {
          setIsLightboxOpen(true);
          setActiveSlideNumber(index);
        }}
        onContextMenu={(e) => e.preventDefault()}
      >
        <GatsbyImage
          image={image.gatsbyImageData}
          alt={product.title}
          draggable="false"
        />
      </ImageContainer>
    );
  });

  const thumbnail = productImages.filter((image, index) => index === 0)[0];
  const additionalImages = productImages.filter((image, index) => index > 0);

  // const metafields = product.metafields.length >= 1 ? product.metafields : null;

  const certificateOfAuthenticity = getMetafield(
    product,
    "certificate_of_authenticity"
  );
  const priceOnApplication = getMetafield(product, "price_on_application");
  const literatureMetafield = getMetafield(product, "literature");
  const conditionMetafield = getMetafield(product, "condition");
  const provenanceMetafield = getMetafield(product, "provenance");
  const framingMetafield = getMetafield(product, "framing");
  const artistMetafield = getMetafield(product, "artist");
  const additionalTextLeftMetafield = getMetafield(product, "additional_text");
  const additionalTextLeftCreditMetafield = getMetafield(
    product,
    "additional_text_left_credit"
  );
  const additionalTextRightMetafield = getMetafield(
    product,
    "additional_text_right"
  );
  const additionalTextRightCreditMetafield = getMetafield(
    product,
    "additional_text_right_credit"
  );

  const relatedProductOne = getMetafield(product, "related_product_1");
  const relatedProductTwo = getMetafield(product, "related_product_2");
  const relatedProductThree = getMetafield(product, "related_product_3");

  const relatedProductOverride =
    relatedProductOne !== null ||
    relatedProductTwo !== null ||
    relatedProductThree !== null
      ? true
      : false;

  const [openDropdown, setOpenDropdown] = useState(null);

  return (
    <>
      <PageSeo
        // seoTitle={`${product.seo.title} | RAW Editions`}
        seoTitle={`RAW Editions | ${product.seo.title}`}
        seoText={product.seo.description}
        seoImage={
          product.featuredImage !== null
            ? product.featuredImage.originalSrc
            : ``
        }
      />
      <Page height={height}>
        <div className="header" />

        <div className="mobile-title">
          <h2>
            {artistMetafield !== null && (
              <Link
                to={`/shop/`}
                state={{
                  activeArtistFilter: artistMetafield,
                }}
              >
                <span className="shop-title">Shop</span>
                <span className="arrow">
                  <MobileArrow />
                </span>
                <span className="artist-title">{artistMetafield}</span>
              </Link>
            )}
          </h2>
        </div>

        <Grid className="introduction">
          <div className="product-details">
            <div className="title-bar">
              <h1>
                <span className="artwork-title">{product.title}</span>
                {artistMetafield !== null && (
                  <span className="artist-title uppercase">
                    <Link
                      to={`/shop/`}
                      state={{
                        activeArtistFilter: artistMetafield,
                      }}
                    >
                      {artistMetafield}
                    </Link>
                  </span>
                )}
              </h1>
            </div>

            <div className="left-column">
              <div className="image-container">{thumbnail}</div>
            </div>

            <div className="right-column">
              <div
                className="text-container"
                dangerouslySetInnerHTML={{
                  __html: product.descriptionHtml,
                }}
              />

              <div className="dropdowns-container">
                {certificateOfAuthenticity !== null && (
                  <div
                    className={`details authenticity-container ${
                      openDropdown === `authenticity` ? `open` : ``
                    }`}
                  >
                    <button
                      onClick={() => {
                        if (openDropdown === `authenticity`) {
                          setOpenDropdown(null);
                        } else {
                          setOpenDropdown(`authenticity`);
                        }
                      }}
                    >
                      <ProductToggle />
                      <span>Certificate of Authenticity</span>
                    </button>
                    <div className="text-container">
                      <p>{certificateOfAuthenticity}</p>
                    </div>
                  </div>
                )}

                {literatureMetafield !== null && (
                  <div
                    className={`details literature-container ${
                      openDropdown === `literature` ? `open` : ``
                    }`}
                  >
                    <button
                      onClick={() => {
                        if (openDropdown === `literature`) {
                          setOpenDropdown(null);
                        } else {
                          setOpenDropdown(`literature`);
                        }
                      }}
                    >
                      <ProductToggle />
                      <span>Literature</span>
                    </button>

                    <div className="text-container">
                      <p>{literatureMetafield}</p>
                    </div>
                  </div>
                )}

                {provenanceMetafield !== null && (
                  <div
                    className={`details provenance-container ${
                      openDropdown === `provenance` ? `open` : ``
                    }`}
                  >
                    <button
                      onClick={() => {
                        if (openDropdown === `provenance`) {
                          setOpenDropdown(null);
                        } else {
                          setOpenDropdown(`provenance`);
                        }
                      }}
                    >
                      <ProductToggle />
                      <span>Provenance</span>
                    </button>
                    <div className="text-container">
                      <p>{provenanceMetafield}</p>
                    </div>
                  </div>
                )}

                {conditionMetafield !== null && (
                  <div
                    className={`details condition-container ${
                      openDropdown === `condition` ? `open` : ``
                    }`}
                  >
                    <button
                      onClick={() => {
                        if (openDropdown === `condition`) {
                          setOpenDropdown(null);
                        } else {
                          setOpenDropdown(`condition`);
                        }
                      }}
                    >
                      <ProductToggle />
                      <span>Condition</span>
                    </button>

                    <div className="text-container">
                      <p>{conditionMetafield}</p>
                    </div>
                  </div>
                )}

                {framingMetafield !== null && (
                  <div
                    className={`details framing-container ${
                      openDropdown === `framing` ? `open` : ``
                    }`}
                  >
                    <button
                      onClick={() => {
                        if (openDropdown === `framing`) {
                          setOpenDropdown(null);
                        } else {
                          setOpenDropdown(`framing`);
                        }
                      }}
                    >
                      <ProductToggle />
                      <span>Framing</span>
                    </button>
                    <div className="text-container">
                      <p>{framingMetafield}</p>
                    </div>
                  </div>
                )}
              </div>

              <div className="links-container">
                <ol>
                  <li>
                    <a href="/support/" target="_blank">
                      <ProductArrow /> <span>Collector Support</span>
                    </a>
                  </li>

                  <li>
                    <a href="/our-alphabet/" target="_blank">
                      <ProductArrow /> <span>Our Alphabet</span>
                    </a>
                  </li>

                  <li>
                    <button
                      onClick={() => {
                        setIsProductEnquiryOpen(true);
                        setFormType(`sell`);
                      }}
                    >
                      <ProductArrow /> <span>Sell</span>
                    </button>
                  </li>
                </ol>
              </div>

              <div className="share-links-container">
                <ShareButtons
                  title={`RAW Editions | ${product.seo.title}`}
                  text={product.seo.description}
                  image={
                    product.featuredImage !== null
                      ? product.featuredImage.originalSrc
                      : ``
                  }
                />
              </div>
            </div>
          </div>
        </Grid>

        {(additionalImages.length >= 1 ||
          additionalTextLeftMetafield !== null ||
          additionalTextLeftCreditMetafield !== null ||
          additionalTextRightMetafield !== null ||
          additionalTextRightCreditMetafield !== null) && (
          <div className="content-container">
            {additionalImages}

            <Grid className="content text-content-container order-3">
              {additionalTextLeftMetafield !== null && (
                <div className="text-container left">
                  <div className="text">
                    <p>{additionalTextLeftMetafield}</p>
                  </div>

                  {additionalTextLeftCreditMetafield !== null && (
                    <div className="credit">
                      <p>— {additionalTextLeftCreditMetafield}</p>
                    </div>
                  )}
                </div>
              )}
            </Grid>

            <Grid className="content text-content-container order-4">
              {additionalTextRightMetafield !== null && (
                <div className="text-container right">
                  <div className="text">
                    <p>{additionalTextRightMetafield}</p>
                  </div>

                  {additionalTextRightCreditMetafield !== null && (
                    <div className="credit">
                      <p>— {additionalTextRightCreditMetafield}</p>
                    </div>
                  )}
                </div>
              )}
            </Grid>
          </div>
        )}

        <RelatedProducts
          products={data.allShopifyProduct.nodes}
          relatedProductOne={relatedProductOne}
          relatedProductTwo={relatedProductTwo}
          relatedProductThree={relatedProductThree}
          relatedProductOverride={relatedProductOverride}
          productTags={productTags}
          getArtistName={getArtistName}
          getPriceOnApplication={getPriceOnApplication}
          height={height}
          product={product}
        />

        <Banner
          isBannerVisible={isBannerVisible}
          product={product}
          productVariant={productVariant}
          available={available}
          priceOnApplication={priceOnApplication}
          setFormType={setFormType}
          setIsProductEnquiryOpen={setIsProductEnquiryOpen}
          artistMetafield={artistMetafield}
          getPriceOnApplication={getPriceOnApplication}
          price={price}
        />
      </Page>

      {isLightboxOpen && (
        <Lightbox
          isLightboxOpen={isLightboxOpen}
          setIsLightboxOpen={setIsLightboxOpen}
          images={product.images}
          activeSlideNumber={activeSlideNumber}
        />
      )}

      {isProductEnquiryOpen && (
        <ProductEnquiryForm
          formType={formType}
          isProductEnquiryOpen={isProductEnquiryOpen}
          setIsProductEnquiryOpen={setIsProductEnquiryOpen}
          title={product.title}
          artist={getArtistName(product)}
          url={location.href}
        />
      )}

      <form
        name="sell-enquiry"
        data-netlify="true"
        netlify-honeypot="bot-field"
        hidden
      >
        <input type="hidden" name="subject" />
        <input type="hidden" name="url" />
        <input type="hidden" name="email" />
        <textarea type="hidden" name="message" />
      </form>

      <form
        name="artwork-enquiry"
        data-netlify="true"
        netlify-honeypot="bot-field"
        hidden
      >
        <input type="hidden" name="subject" />
        <input type="hidden" name="url" />
        <input type="hidden" name="email" />
        <textarea type="hidden" name="message" />
      </form>
    </>
  );
};

export default Product;

export const query = graphql`
  query ($handle: String!) {
    allShopifyProduct(
      filter: { handle: { ne: $handle }, totalInventory: { gte: 1 } }
    ) {
      nodes {
        title
        handle
        shopifyId
        metafields {
          value
          key
        }
        images {
          id
          width
          height
          src
          gatsbyImageData
        }
        tags
        totalInventory
        priceRangeV2 {
          maxVariantPrice {
            amount
            currencyCode
          }
          minVariantPrice {
            amount
            currencyCode
          }
        }
      }
    }
    shopifyProduct(handle: { eq: $handle }) {
      title
      descriptionHtml
      description
      productType
      tags
      handle
      priceRangeV2 {
        maxVariantPrice {
          amount
          currencyCode
        }
        minVariantPrice {
          amount
          currencyCode
        }
      }
      storefrontId
      images {
        id
        width
        height
        src
        gatsbyImageData
      }
      featuredImage {
        originalSrc
      }
      metafields {
        value
        key
      }
      variants {
        availableForSale
        storefrontId
        shopifyId
        title
        price
        selectedOptions {
          name
          value
        }
      }
      seo {
        description
        title
      }
      totalInventory
      options {
        name
        values
        id
      }
    }
  }
`;
