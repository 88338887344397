import React, { useEffect } from "react";
import { Link } from "gatsby";
import { PrismicRichText } from "@prismicio/react";
import { useAsyncFn } from "react-use";

// Utils
import kebabCase from "lodash.kebabcase";

// Containers
import { AspectRatioImageContainer } from "../containers/aspect-ratio-image-container";

export const ArtistArtwork = ({ content, data }) => {
  const [artistUrl, getArtistUrl] = useAsyncFn(async (id) => {
    const response = await fetch(`/.netlify/functions/generate-product-url`, {
      method: "POST",
      body: JSON.stringify({
        id: id,
      }),
      headers: new Headers({
        "Content-Type": "application/json",
      }),
    });

    const result = await response.json();

    if (result !== ``) {
      return `/${kebabCase(result)}/`;
    } else {
      return ``;
    }
  }, []);

  useEffect(() => {
    if (
      content.primary.artwork !== null &&
      (content.primary.artwork.status === "ACTIVE" ||
        content.primary.artwork.status === "active")
    ) {
      getArtistUrl(content.primary.artwork.admin_graphql_api_id);
    }
  }, []);

  const getUrl = (content) => {
    if (
      content.primary.artwork !== null &&
      (content.primary.artwork.status === "ACTIVE" ||
        content.primary.artwork.status === "active")
    ) {
      return `${artistUrl.value}${content.primary.artwork.handle}/`;
    } else {
      return `/shop/`;
    }
  };

  return (
    <div
      className={`single-module artwork-container position-${kebabCase(
        content.primary.artwork_position
      )} size-${kebabCase(content.primary.artwork_size)}`}
    >
      <Link
        to={getUrl(content)}
        state={{ activeArtistFilter: data.prismicArtist.data.title.text }}
      >
        {content.primary.artwork_thumbnail.fluid !== null ? (
          <AspectRatioImageContainer image={content.primary.artwork_thumbnail}>
            <img
              srcSet={content.primary.artwork_thumbnail.fluid.srcSet}
              src={content.primary.artwork_thumbnail.fluid.src}
              alt={content.primary.artwork_thumbnail.alt}
              loading={`lazy`}
              onContextMenu={(e) => e.preventDefault()}
              draggable="false"
            />
          </AspectRatioImageContainer>
        ) : (
          <>
            {content.primary.artwork !== null && (
              <AspectRatioImageContainer
                image={null}
                padding={
                  (content.primary.artwork.image.height /
                    content.primary.artwork.image.width) *
                  100
                }
              >
                <img
                  src={content.primary.artwork.image.src}
                  onContextMenu={(e) => e.preventDefault()}
                  draggable="false"
                />
              </AspectRatioImageContainer>
            )}
          </>
        )}
      </Link>

      {content.primary.artwork !== null ? (
        <p>{content.primary.artwork.title}</p>
      ) : (
        <PrismicRichText field={content.primary.artwork_title.richText} />
      )}
    </div>
  );
};
