import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { useFlexSearch } from "react-use-flexsearch";
import { navigate } from "gatsby";
import { useClickAway } from "react-use";

// Icons
import { SearchIcon } from "../icons/menu-icons";

const SearchContainer = styled.div`
  width: 100%;
  max-width: 400px;
  margin: 0 0 0 auto;

  @media (max-width: 800px) {
    height: 32px;
    margin: 0;
  }
`;

const SearchForm = styled.form`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  width: 100%;

  & .close-search {
    & svg {
      & path {
        transition: 250ms stroke ease;
      }
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        & svg {
          & path {
            stroke: ${(props) => props.color};
          }
        }
      }
    }
  }

  & .input-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;

    width: calc(100% - 55px);

    border-bottom: 1px solid #91a8b7;

    & input {
      padding: 0;
      height: 20px;

      border: 0;

      width: calc(100% - 50px);
      color: #00;

      background-color: transparent;

      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.05em;

      &:focus {
        outline: none;
      }
    }

    & button[type="submit"] {
      font-size: 12px;
      line-height: 15px;

      padding: 5px 0 0 10px;
      text-align: right;
      transition: 250ms color ease;

      @media (max-width: 800px) {
        padding: 5px 0 0 0;
      }

      @media (hover: hover) and (pointer: fine) {
        &:hover {
          color: ${(props) => props.color};
        }
      }
    }
  }

  @media (max-width: 800px) {
    max-width: 100%;

    & button,
    & button[type="submit"] {
      margin: 0;
      padding: 0;

      order: 1;
      align-items: center;

      letter-spacing: 0.05em;
    }

    & .input-container {
      order: 1;

      & input {
        height: auto;
        line-height: 20px;
        width: calc(100% - 40px);
      }
    }

    & button[type="submit"] {
      order: 11;
    }

    & .close-search {
      order: 12;
    }
  }
`;

export const Search = ({
  index,
  store,
  isSearchOpen,
  setIsSearchOpen,
  underlineColor,
  hoverColor,
  location,
}) => {
  const [query, setQuery] = useState("");
  const results = useFlexSearch(query, index, store);

  const ref = useRef(null);
  useClickAway(ref, () => {
    setIsSearchOpen(false);
  });

  const navigateToSearchResultsPage = (event) => {
    event.preventDefault();
    setIsSearchOpen(false);
    navigate(`/search/?q=${query}`, {
      state: {
        searchResults: results,
        searchQuery: query,
      },
    });
  };

  useEffect(() => {
    if (typeof window !== undefined && isSearchOpen === true) {
      document.getElementById("search-input").focus();
    }
  }, [isSearchOpen]);

  return (
    <SearchContainer color={hoverColor} ref={ref}>
      {isSearchOpen ? (
        <SearchForm
          onSubmit={navigateToSearchResultsPage}
          color={underlineColor}
        >
          <button
            type="button"
            onClick={() => setIsSearchOpen(false)}
            className="close-search"
          >
            <SearchIcon fill={hoverColor} />
          </button>

          <div className="input-container">
            <input
              id="search-input"
              name="query"
              type="text"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
            />

            <button type="submit">Search</button>
          </div>
        </SearchForm>
      ) : (
        <button onClick={() => setIsSearchOpen(true)}>
          <SearchIcon fill={hoverColor} />
        </button>
      )}
    </SearchContainer>
  );
};
