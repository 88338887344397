import React from "react";

export const CloseFiltersIcon = () => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1 1L13.5 13.5" stroke="black" strokeWidth="1" />
    <path d="M13.5 1L0.999999 13.5" stroke="black" strokeWidth="1" />
  </svg>
);
