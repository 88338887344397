import React, { useContext, useEffect, useState } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";

// Previews
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

// SEO
import { PageSeo } from "../components/seo/seo";

// Context
import { PageType } from "../components/context/page-type";

// Hooks
import { useMediaQuery } from "../components/hooks/useMediaQuery";

// Components
import { OurAlphabetDesktop } from "../components/our-alphabet/our-alphabet-desktop";
import { OurAlphabetMobile } from "../components/our-alphabet/our-alphabet-mobile";

const Page = styled.div`
  display: grid;
  grid-template-columns: 1fr 30px 1fr;
  grid-column-gap: 20px;

  & .header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;

    height: 75px;

    transition: 249ms background-color ease;
    background-color: #e9cfc0;

    z-index: 1;

    @media (max-width: 800px) {
      height: 56px;
    }
  }

  @media (max-width: 800px) {
    display: block;
    grid-template-columns: unset;
    grid-column-gap: unset;
  }

  & ol {
    grid-column: 2;

    @media (max-width: 800px) {
      grid-column: 1;
      order: 1;
    }

    & li {
      text-align: center;

      &.active {
        & button {
          color: #87a3b4;
        }
      }

      & button {
        font-size: 30px;
        line-height: 44px;
        transition: 250ms color ease;

        @media (hover: hover) and (pointer: fine) {
          &:hover {
            color: #87a3b4;
          }
        }
      }
    }
  }

  & .active-items {
    max-width: 90%;
    width: 100%;

    &.left {
      grid-column: 1;
    }

    &.right {
      grid-column: 3;
    }

    & .inner-container {
      // position: sticky;

      @media (max-width: 800px) {
        position: relative;
      }
    }

    @media (max-width: 800px) {
      &.left,
      &.right {
        grid-column: 2;
      }
    }

    &.index-odd {
      &.left {
        margin: 200px 0 0 0;

        @media (max-height: 800px) {
          margin: 0 0 0 0;
        }

        & .inner-container {
          // top: 390px;

          @media (max-width: 800px) {
            top: 0;
          }
        }

        @media (max-width: 800px) {
          margin: 0;
          order: 2;
        }
      }

      &.right {
        margin: 110px 0 0 auto;

        @media (max-height: 800px) {
          margin: 0 0 0 0;
        }

        & .inner-container {
          // top: 300px;

          @media (max-width: 800px) {
            top: 0;
          }
        }

        @media (max-width: 800px) {
          margin: 0;
          order: 3;
        }
      }

      & .active-items-container {
        &:nth-of-type(odd) {
          margin: 0 0 120px 0;
        }

        &:nth-of-type(even) {
          margin: 0 0 120px auto;
        }
      }
    }

    &.index-even {
      &.left {
        margin: 110px 0 0 0;

        @media (max-height: 800px) {
          margin: 0 0 0 0;
        }

        & .inner-container {
          // top: 300px;

          @media (max-width: 800px) {
            top: 0;
          }
        }

        @media (max-width: 800px) {
          margin: 0;
          order: 2;
        }
      }

      &.right {
        margin: 200px 0 0 0;

        @media (max-height: 800px) {
          margin: 0 0 0 0;
        }

        & .inner-container {
          // top: 390px;

          @media (max-width: 800px) {
            top: 0;
          }
        }

        @media (max-width: 800px) {
          margin: 0;
          order: 3;
        }
      }

      & .active-items-container {
        &:nth-of-type(odd) {
          margin: 0 0 120px auto;
        }

        &:nth-of-type(even) {
          margin: 0 0 120px 0;
        }
      }
    }

    & .active-items-container {
      max-width: 450px;

      & h3 {
        font-size: 16px;
        line-height: 28px;
        letter-spacing: 0.04em;
        text-align: center;

        writing-mode: vertical-rl;
        text-orientation: mixed;

        width: 100%;
        max-width: calc(450px / 2);
        margin: 0 0 50px 0;

        @media (max-width: 1440px) {
          font-size: 14px;
        }
      }

      & p {
        margin: 0;
        max-width: 450px;

        font-size: 14px;
        line-height: 19px;

        @media (max-width: 500px) {
          font-size: 10px;
          line-height: 14px;
        }
      }
    }
  }
`;

const OurAlphabet = ({ data, location }) => {
  const [pageType, setPageType] = useContext(PageType);
  const [activeIndex, setActiveIndex] = useState(null);

  // Media Query
  let isDesktop = useMediaQuery("(min-width: 801px)");

  const isOdddOrEven = (number) => (number % 2 ? `even` : `odd`);

  useEffect(() => {
    setPageType({
      page: `glossary`,
      background: `#E9CFC0`,
      banner: true,
      bannerBackground: `#E9CFC0`,
      fillColor: `#91A8B7`,
      hoverColor: `#91A8B7`,
      underlineColor: `#91A8B7`,
    });
  }, []);

  useEffect(() => {
    if (activeIndex !== null) {
      if (typeof window !== undefined) {
        const element =
          document
            .getElementById(`container-${activeIndex}`)
            .getBoundingClientRect().top +
          window.pageYOffset -
          100;

        window.scrollTo({ top: element, behavior: "smooth" });
      }
    }
  }, [activeIndex]);

  return (
    <>
      <PageSeo
        seoTitle="Our Alphabet | RAW Editions"
        seoImage={null}
        seoText={null}
      />

      <Page>
        <div className="header" />

        {isDesktop ? (
          <OurAlphabetDesktop
            data={data}
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
            location={location}
            isOdddOrEven={isOdddOrEven}
          />
        ) : (
          <OurAlphabetMobile data={data} location={location} />
        )}
      </Page>
    </>
  );
};

export const query = graphql`
  {
    prismicGlossary {
      _previewable
      data {
        title {
          text
        }
        body {
          ... on PrismicGlossaryDataBodyGlossaryLetter {
            id
            slice_type
            primary {
              letter {
                text
              }
            }
            items {
              section_title {
                richText
              }
              text {
                richText
              }
            }
          }
        }
      }
    }
  }
`;

export default withPrismicPreview(OurAlphabet);
