import React, { useContext, useEffect } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import { PrismicRichText } from "@prismicio/react";

// Previews
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

// SEO
import { PageSeo } from "../components/seo/seo";

// Context
import { PageType } from "../components/context/page-type";

// Hooks
import { FairsGallery } from "../components/images/gallery";

const Page = styled.div`
  cursor: url(/icons/cursor-circle-on-buff@1x.png) 9 9, pointer !important;
  cursor: -webkit-image-set(
        url(/icons/cursor-circle-on-buff@1x.png) 1x,
        url(/icons/cursor-circle-on-buff@2x.png) 2x
      )
      9 9,
    pointer !important;

  & .header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;

    height: 75px;

    transition: 249ms background-color ease;
    background-color: #e9cfc0;

    z-index: 1;

    @media (max-width: 800px) {
      height: 56px;
    }
  }

  & .fairs-container {
    position: relative;
    z-index: 0;

    & .fair {
      max-width: 570px;
      margin: 0 0 55px 0;

      @media (max-width: 500px) {
        margin: 0 0 40px 0;
      }

      & .fair-title {
        margin: 0 0 30px 0;

        @media (max-width: 500px) {
          margin: 0 0 20px 0;
        }

        & p {
          font-size: 16px;
          line-height: 22px;

          margin: 0;

          @media (max-width: 500px) {
            font-size: 11px;
            line-height: 13px;
          }

          & strong {
            font-size: 16px;
            line-height: 22px;
            letter-spacing: 0.02em;
            color: #658ba5;

            @media (max-width: 500px) {
              font-size: 11px;
              line-height: 13px;
            }
          }
        }
      }

      &:first-of-type {
        max-width: 810px;

        & .fair-title {
          margin: 0 0 50px 0;

          @media (max-width: 500px) {
            margin: 0 0 20px 0;
          }

          @media (max-height: 800px) {
            margin: 0 0 30px 0;
          }

          & p {
            font-size: 20px;
            line-height: 28px;

            @media (max-width: 1440px) {
              font-size: 16px;
              line-height: 22px;
            }

            @media (max-width: 500px) {
              font-size: 13px;
              line-height: 18px;
            }

            & strong {
              font-size: 16px;
              line-height: 28px;
              letter-spacing: 0.02em;
              color: #658ba5;

              @media (max-width: 1440px) {
                font-size: 13px;
                line-height: 18px;
              }

              @media (max-width: 500px) {
                font-size: 11px;
                line-height: 13px;
              }
            }
          }
        }
      }
    }

    & .breaker {
      width: 100vw;
      margin: 0 -20px 55px -20px;

      border-bottom: 1px solid #a8b8c1;

      @media (max-width: 500px) {
        display: none;
      }
    }

    & .image-caption {
      margin: 10px 0 0 0;

      & p {
        font-size: 14px;
        line-height: 19px;

        margin: 0;

        & strong {
          letter-spacing: 0.08em;
        }
      }
    }
  }
`;

const Fairs = ({ data, location }) => {
  const [pageType, setPageType] = useContext(PageType);

  useEffect(() => {
    setPageType({
      page: `fairs`,
      background: `#E9CFC0`,
      banner: true,
      bannerBackground: `#E9CFC0`,
      fillColor: `#91A8B7`,
      hoverColor: `#91A8B7`,
      underlineColor: `#91A8B7`,
    });
  }, []);

  const content = data.prismicFairs.data.body.map((content, index) => {
    const images = content.items.filter((image) => image.image.fluid !== null);

    return (
      <React.Fragment key={`single_fair_${index}_${content.id}`}>
        <div className={`fair`}>
          <div className="fair-title">
            <PrismicRichText field={content.primary.text.richText} />
          </div>

          {images.length >= 1 && (
            <div className="gallery-container">
              <FairsGallery images={images} index={index} id={content.id} />
            </div>
          )}
        </div>

        {index === 0 && <div className="breaker" />}
      </React.Fragment>
    );
  });

  return (
    <>
      <PageSeo seoTitle="Fairs | RAW Editions" seoImage={null} seoText={null} />

      <Page>
        <div className="header" />

        <div className="fairs-container">{content}</div>
      </Page>
    </>
  );
};

export const query = graphql`
  {
    prismicFairs {
      _previewable
      data {
        body {
          ... on PrismicFairsDataBodyFair {
            id
            primary {
              text {
                richText
              }
            }
            items {
              image {
                fluid {
                  srcSet
                  src
                  aspectRatio
                }
                dimensions {
                  width
                  height
                }
                alt
              }
              image_caption {
                richText
                text
              }
            }
          }
        }
      }
    }
  }
`;

export default withPrismicPreview(Fairs);
