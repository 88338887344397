import React from "react";

export const NextArrow = () => (
  <svg
    width="101"
    height="19"
    viewBox="0 0 101 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 9L99 9.00001" stroke="black" strokeWidth="1.5" />
    <path
      d="M91 1L99.6735 9.32653L91.3469 18"
      stroke="black"
      strokeWidth="1.5"
    />
  </svg>
);

export const PrevArrow = () => (
  <svg
    width="102"
    height="19"
    viewBox="0 0 102 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M101.674 10L2.67382 9.99998" stroke="black" strokeWidth="1.5" />
    <path
      d="M10.6738 18L2.00036 9.67347L10.3269 0.999999"
      stroke="black"
      strokeWidth="1.5"
    />
  </svg>
);

export const Close = () => (
  <svg
    width="19"
    height="18"
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.3175 8.74224L17.5294 1.53039L16.4687 0.469727L9.25684 7.68158L2.04505 0.469799L0.984393 1.53046L8.19618 8.74224L0.468765 16.4697L1.52943 17.5303L9.25684 9.8029L16.9843 17.5304L18.045 16.4697L10.3175 8.74224Z"
      fill="#1D1D1B"
    />
  </svg>
);
