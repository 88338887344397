import React, { useCallback } from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";
import kebabCase from "lodash.kebabcase";
import LazyLoad from "react-lazyload";
// import { Fade } from "react-awesome-reveal";

const ArtworkContainer = styled.div`
  & .fade-container {
    z-index: 0;
    position: relative;
    pointer-events: none;
  }

  & .aspect-ratio-container {
    max-height: calc(${(props) => props.height}px - 250px);
    height: 100%;

    @media (max-height: 800px) {
      max-height: calc(${(props) => props.height}px - 300px);
    }

    & img {
      width: 100%;
      height: 100%;

      max-height: calc(${(props) => props.height}px - 250px);

      @media (max-height: 800px) {
        max-height: calc(${(props) => props.height}px - 300px);
      }

      object-fit: contain !important;
      object-position: left;
    }
  }

  &.overview-view {
    & a {
      width: 100%;

      & .aspect-ratio-container {
        margin: 0 auto;

        & img {
          object-position: center;
        }
      }
    }
  }
`;

export const HomepageArtwork = React.memo(
  ({
    artwork,
    index,
    view,
    activeArtwork,
    setIsArtworkActive,
    setActiveArtwork,
    getArtistName,
    isMobile,
    height,
  }) => {
    const handleMouseOver = useCallback(() => {
      if (!isMobile && view === "gallery" && activeArtwork !== index) {
        setIsArtworkActive(true);
        setActiveArtwork(index);
      }
    }, [
      isMobile,
      view,
      activeArtwork,
      index,
      setIsArtworkActive,
      setActiveArtwork,
    ]);

    return (
      <ArtworkContainer
        height={height}
        className={`artwork-container ${
          activeArtwork === index ? `active-artwork` : ``
        } ${view}-view`}
      >
        <Link
          to={`/${kebabCase(getArtistName(artwork))}/${artwork.handle}/`}
          onMouseOver={handleMouseOver}
          onMouseOut={() => {
            if (!isMobile) {
              if (view === `gallery`) {
                setIsArtworkActive(false);
                setActiveArtwork(null);
              }
            }
          }}
        >
          <div
            className="aspect-ratio-container"
            onContextMenu={(e) => e.preventDefault()}
          >
            <LazyLoad height={300}>
              {artwork.featuredImage !== null && (
                <GatsbyImage
                  image={artwork.featuredImage.gatsbyImageData}
                  alt={artwork.title}
                  draggable="false"
                  fetchpriority={index < 3 ? `high` : `low`}
                  loading={index < 3 ? `eager` : `lazy`}
                />
              )}
            </LazyLoad>
          </div>

          <div className="artwork-details">
            <p className="artwork-title">{artwork.title}</p>
            <p className="artist-title uppercase">
              {getArtistName(artwork) !== `` && <>{getArtistName(artwork)}</>}
            </p>
            <p className="sold-out">
              {artwork.totalInventory < 1 ? (
                <span className="sold-out-span"></span>
              ) : (
                ``
              )}
            </p>
          </div>
        </Link>
      </ArtworkContainer>
    );
  }
);
